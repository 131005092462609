import { isEqual } from "lodash";
import { computed, ComputedRef } from "vue";

import { PushMessage } from "@/api/pushnotifications/pushmessage/typedefs";

import { UsePushMessageChangesWatcher } from "./typedefs";

export const usePushMessageChangesWatcher: UsePushMessageChangesWatcher = ({
  pushMessage,
  notEditedPushMessage,
}) => {
  const getContentFields = (message: PushMessage) => {
    const contentKeys: Array<keyof PushMessage> = [
      "name",
      "title",
      "text",
      "url",
      "campaignId",
      "image",
      "deliveryType",
      "deliveryOptions",
      "isDryRun",
      "tags",
    ];

    return contentKeys.map((key) => message[key]);
  };

  const hasUnsavedChanges: ComputedRef<boolean> = computed(() => {
    const currentTarget = pushMessage.value.target;
    const oldTarget = notEditedPushMessage.value.target;

    const currentContent = getContentFields(pushMessage.value);
    const oldContent = getContentFields(notEditedPushMessage.value);

    return (
      !isEqual(currentContent, oldContent) ||
      currentTarget?.id !== oldTarget?.id
    );
  });

  return { hasUnsavedChanges };
};
