
import { defineComponent } from "@vue/runtime-core";
import useDialogPluginComponent from 'quasar/src/composables/use-dialog-plugin-component.js';;
import { Ref, ref } from "vue";

export default defineComponent({
  emits: Array.from(useDialogPluginComponent.emits),
  setup() {
    const { dialogRef, onDialogOK, onDialogHide } = useDialogPluginComponent();
    const isLoading = ref(false);
    const rationale: Ref<string | null> = ref(null);

    const onSubmit = async () => {
      isLoading.value = true;
      onDialogOK({ rationale: rationale.value });
    };

    return {
      isLoading,
      rationale,
      onDialogHide,
      dialogRef,
      onSubmit,
    };
  },
});
