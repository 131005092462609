
import { defineComponent } from "@vue/runtime-core";
import useDialogPluginComponent from 'quasar/src/composables/use-dialog-plugin-component.js';;
import { PropType, Ref, ref } from "vue";

import { PRODUCT_LINES, ProductLine } from "@/lib/productLine";

const productLineOptionLabel = (productLine: ProductLine | null) =>
  productLine === null
    ? ""
    : `${productLine.productName} / ${productLine.name}`;

export default defineComponent({
  props: {
    defaultProductLine: {
      type: null as unknown as PropType<ProductLine | null>,
      required: false,
      default: null,
      validator: (v: unknown) => v === null || typeof v === "object",
    },
    productLineOptions: {
      type: null as unknown as PropType<ProductLine[]>,
      required: false,
      default: PRODUCT_LINES,
      validator: (v: unknown) => v === null || typeof v === "object",
    },
  },

  emits: Array.from(useDialogPluginComponent.emits),
  setup(props) {
    const { dialogRef, onDialogOK, onDialogHide } = useDialogPluginComponent();
    const isLoading = ref(false);
    const productLine: Ref<ProductLine | null> = ref(props.defaultProductLine);

    const onSubmit = async () => {
      if (productLine.value === null) return;
      isLoading.value = true;

      onDialogOK({
        productLine: productLine.value.id,
        product: productLine.value.productId,
      });
    };

    return {
      isLoading,
      productLine,
      productLineOptionLabel,
      onDialogHide,
      dialogRef,
      onSubmit,
    };
  },
});
