import { computed } from "vue";

import { usePermissions } from "@/composables/usePermissions";
import { getProductLine, PRODUCT_LINES, ProductLine } from "@/lib/productLine";
import { UsePushMessagePermissions } from "@/views/pushmessages/typedefs";

export const usePushMessagePermissions: UsePushMessagePermissions = ({
  productLineId,
}) => {
  const { hasPushMessagePermission, hasBasePermission } = usePermissions();
  const productLine = computed(() => getProductLine(productLineId.value));

  const hasAddPushMessagePermission = computed(() =>
    hasPushMessagePermission("add_message", productLine.value)
  );

  const hasEditPushMessagePermission = computed(() =>
    hasPushMessagePermission("change_message", productLine.value)
  );

  const hasAddImagePermission = computed(() => hasBasePermission("add_image"));

  const hasAddTargetPermission = computed(() =>
    hasPushMessagePermission("add_messagetarget", productLine.value)
  );

  const hasDryRunPermissions = computed(() =>
    hasPushMessagePermission("dry_run", productLine.value)
  );
  const productLinesWithAddMessagePermissions = computed(() =>
    PRODUCT_LINES.filter((productLine: ProductLine) =>
      hasPushMessagePermission("add_message", productLine)
    )
  );

  return {
    hasAddPushMessagePermission,
    hasEditPushMessagePermission,
    hasAddImagePermission,
    hasAddTargetPermission,
    hasDryRunPermissions,
    productLinesWithAddMessagePermissions,
  };
};
