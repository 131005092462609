import {
  DeliveryOptions,
  DeliveryType,
  isDeliveryOptionsRecurring,
  isDeliveryOptionsScheduled,
  isDeliveryOptionsTriggered,
} from "@/api/pushnotifications/messagedelivery/typedefs";
import {
  ExistingPushMessage,
  NewPushMessageComplete,
} from "@/api/pushnotifications/pushmessage/typedefs";
import { Tag } from "@/api/tags/typedefs";
import { httpClient, throwIfBadResponse } from "@/lib/http";

import { convertPushMessage } from "./details";

export function buildDeliveryOptionsQueryParams(
  deliveryType: DeliveryType,
  options: DeliveryOptions
): Record<string, string | number | null | boolean> {
  const defaults: Record<string, string | number | null> = {
    start_datetime: null,
    end_datetime: null,
    interval: null,
    time: null,
    date_time: null,
  };
  if (
    deliveryType === DeliveryType.RECURRING &&
    isDeliveryOptionsRecurring(options)
  ) {
    return {
      ...defaults,
      start_datetime: options.dateRange.from,
      end_datetime: options.dateRange.to,
      interval: options.interval,
      time: options.time,
    };
  } else if (
    deliveryType === DeliveryType.SCHEDULED &&
    isDeliveryOptionsScheduled(options)
  ) {
    return {
      ...defaults,
      date_time: options.dateTime,
    };
  }
  if (
    deliveryType === DeliveryType.TRIGGERED &&
    isDeliveryOptionsTriggered(options)
  ) {
    return {
      ...defaults,
      delay_seconds: options.delaySeconds,
      allow_show_if_app_foreground: options.allowShowIfAppForeground,
      trigger: options.trigger,
      start_datetime: options.startDateTime,
      end_datetime: options.endDateTime,
    };
  } else {
    return {
      ...defaults,
    };
  }
}

export async function createPushMessage(
  productLine: number,
  pushMessage: NewPushMessageComplete
): Promise<ExistingPushMessage> {
  const response = await httpClient.post(
    `/api/pushnotifications/product-lines/${productLine}/messages/`,
    {
      url: pushMessage.url ? pushMessage.url : null,
      campaign_id: pushMessage?.campaignId ?? null,
      name: pushMessage.name,
      title: pushMessage.title,
      message: pushMessage.text,
      image: pushMessage.image?.id ?? null,
      dry_run: pushMessage.isDryRun,
      delivery_type: pushMessage.deliveryType,
      target: pushMessage.target?.id ?? null,
      ...buildDeliveryOptionsQueryParams(
        pushMessage.deliveryType,
        pushMessage.deliveryOptions
      ),
      tags: pushMessage.tags.map((tag: Tag) => tag.id),
    }
  );

  await throwIfBadResponse(response);

  const data = await response.json();

  return convertPushMessage(data);
}
