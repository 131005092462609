import * as t from "io-ts";

import { DateTimeRangeGuard } from "@/lib/dateTime/dateTimeRange";
import { Status, StatusGuard } from "@/lib/status";
import { cast } from "@/lib/typing";

export type MessageDeliveryStatus = Status;
export const MessageDeliveryStatusGuard = StatusGuard;

export const statusOptions = Object.keys(
  StatusGuard.keys
) as MessageDeliveryStatus[];

export const PushMessageMetadataGuard = t.type({
  id: t.number,
  createdBy: t.string,
  name: t.string,
  // TODO(PNS-1519): Use DeliveryType enum after BE changes.
  deliveryType: t.union([t.string, t.null]),
});

export type PushMessageMetadata = t.TypeOf<typeof PushMessageMetadataGuard>;

export const MessageDeliveryGuard = t.type({
  id: t.number,
  status: t.union([MessageDeliveryStatusGuard, t.null]),
  deliveryTime: t.union([t.string, t.null]),
  messagesSent: t.union([t.number, t.null]),
  estimatedUsers: t.union([t.number, t.null]),
  message: PushMessageMetadataGuard,
});

export type MessageDelivery = t.TypeOf<typeof MessageDeliveryGuard>;

export enum DeliveryType {
  IMMEDIATE = "Immediate",
  SCHEDULED = "Scheduled",
  RECURRING = "Recurring",
  TRIGGERED = "Triggered",
}

export const DeliveryTypeGuard = t.keyof({
  [DeliveryType.IMMEDIATE]: null,
  [DeliveryType.SCHEDULED]: null,
  [DeliveryType.RECURRING]: null,
  [DeliveryType.TRIGGERED]: null,
});

export interface DeliveryTypeOption {
  label: string;
  value: DeliveryType;
}

// TODO: Better way to simulate Record<string, never> (empty obj)
export const DeliveryOptionsImmediateGuard = t.record(t.string, t.undefined);
export type DeliveryOptionsImmediate = t.TypeOf<
  typeof DeliveryOptionsImmediateGuard
>;
export const DeliveryOptionsRecurringGuard = t.type({
  time: t.string,
  interval: t.union([t.number, t.null]),
  dateRange: t.type({
    from: t.string,
    to: t.string,
  }),
});
export type DeliveryOptionsRecurring = t.TypeOf<
  typeof DeliveryOptionsRecurringGuard
>;
export const DeliveryOptionsScheduledGuard = t.type({
  dateTime: t.string,
});
export type DeliveryOptionsScheduled = t.TypeOf<
  typeof DeliveryOptionsScheduledGuard
>;
export const DeliveryTriggeredTriggerGuard = t.keyof({
  start: null,
  resume: null,
});
export type DeliveryTriggeredTrigger = t.TypeOf<
  typeof DeliveryTriggeredTriggerGuard
>;
export const DeliveryOptionsTriggeredGuard = t.type({
  delaySeconds: t.number,
  allowShowIfAppForeground: t.boolean,
  trigger: DeliveryTriggeredTriggerGuard,
  startDateTime: t.union([t.string, t.null]),
  endDateTime: t.string,
});
export type DeliveryOptionsTriggered = t.TypeOf<
  typeof DeliveryOptionsTriggeredGuard
>;
export const DeliveryOptionsGuard = t.union([
  DeliveryOptionsImmediateGuard,
  DeliveryOptionsRecurringGuard,
  DeliveryOptionsScheduledGuard,
  DeliveryOptionsTriggeredGuard,
]);
export type DeliveryOptions = t.TypeOf<typeof DeliveryOptionsGuard>;
export const MessageDeliveryMetadataGuard = t.type({
  id: t.number,
  status: t.union([MessageDeliveryStatusGuard, t.null]),
  deliveryTime: t.union([t.string, t.null]),
  messagesSent: t.union([t.number, t.null]),
  estimatedUsers: t.union([t.number, t.null]),
});
export type MessageDeliveryMetadata = t.TypeOf<
  typeof MessageDeliveryMetadataGuard
>;

export function isDeliveryOptionsRecurring(
  options: DeliveryOptions
): options is DeliveryOptionsRecurring {
  try {
    cast(DeliveryOptionsRecurringGuard, options);
    return true;
  } catch (error) {
    return false;
  }
}

export const MessageDeliveryFiltersGuard = t.type({
  name: t.union([t.string, t.null]),
  messageId: t.union([t.string, t.null]),
  status: t.union([MessageDeliveryStatusGuard, t.null]),
  deliveryDateTimeRange: t.union([DateTimeRangeGuard, t.null]),
  createdBy: t.union([t.string, t.null]),
  deliveryType: t.union([DeliveryTypeGuard, t.null]),
  campaignId: t.union([t.string, t.null]),
  tag: t.union([t.number, t.null]),
});

export type MessageDeliveryFilters = t.TypeOf<
  typeof MessageDeliveryFiltersGuard
>;

export function isDeliveryOptionsScheduled(
  options: DeliveryOptions
): options is DeliveryOptionsScheduled {
  try {
    cast(DeliveryOptionsScheduledGuard, options);
    return true;
  } catch (error) {
    return false;
  }
}

export function isDeliveryOptionsTriggered(
  options: DeliveryOptions
): options is DeliveryOptionsTriggered {
  try {
    cast(DeliveryOptionsTriggeredGuard, options);
    return true;
  } catch (error) {
    return false;
  }
}
