import script from "./TargetSelectOption.vue?vue&type=script&setup=true&lang=ts"
export * from "./TargetSelectOption.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBadge,QIcon});
