import { ImageMetadata } from "@/api/image/typedefs";
import { convertPreviewResult } from "@/api/preview/converters";
import { PreviewResult } from "@/api/preview/typedefs";
import { httpClient } from "@/lib/http";

export interface PushMessagePreviewConfig {
  message: string;
  title: string;
  url: string | null;
  image: ImageMetadata | null;
}

export async function pushMessagePreview(
  productLineId: number,
  previewConfig: PushMessagePreviewConfig
): Promise<PreviewResult> {
  const formData = new FormData();
  formData.append("action_type", "url");
  formData.append("message", previewConfig.message);
  formData.append("title", previewConfig.title);
  if (previewConfig.url) {
    formData.append("action_url", previewConfig.url);
  }
  if (previewConfig.image) {
    formData.append("image", String(previewConfig.image.id));
  }

  const response = await httpClient.post(
    `/api/pushnotifications/message/preview/${productLineId}`,
    formData
  );

  const data = await response.json();
  return convertPreviewResult(data);
}
