import { computed, ref, Ref, watch } from "vue";

import { ValidationRule } from "@/lib/rules/typedefs";

interface NumberInputOptions {
  modelValue: Ref<number | null>;
  onNumberChange: (arg: number | null) => void;
  min: Ref<number | string | null>;
  max: Ref<number | string | null>;
  isDecimal: Ref<boolean>;
  extraRules?: Ref<ValidationRule<string | null>[]>;
}

export function useNumberInput({
  modelValue,
  onNumberChange,
  min,
  max,
  isDecimal,
  extraRules,
}: NumberInputOptions) {
  const number: Ref<number | string | null> = ref(null);

  watch(
    [modelValue],
    () => {
      if (modelValue.value === null) {
        number.value = null;
        return;
      }

      if (min.value !== null && modelValue.value < Number(min.value)) {
        number.value = Number(min.value);
        return;
      }

      if (max.value !== null && modelValue.value > Number(max.value)) {
        number.value = Number(max.value);
        return;
      }

      // Remove decimal if not allowed
      if (!isDecimal.value) {
        const decimalSeparator = getDecimalSeparator();
        const decimalIndex = String(number.value).indexOf(decimalSeparator);
        if (decimalIndex !== -1) {
          number.value = Number(
            String(number.value).substring(0, decimalIndex)
          );
        }
      }

      number.value = modelValue.value;
    },
    { immediate: true }
  );

  watch(
    [number],
    () => {
      if (number.value !== null && number.value !== "") {
        if (min.value !== null && number.value < Number(min.value)) {
          number.value = Number(min.value);
        }

        if (max.value !== null && number.value > Number(max.value)) {
          number.value = Number(max.value);
        }

        // Remove decimal if not allowed
        if (!isDecimal.value) {
          const decimalSeparator = getDecimalSeparator();
          const decimalIndex = String(number.value).indexOf(decimalSeparator);
          if (decimalIndex !== -1) {
            number.value = Number(
              String(number.value).substring(0, decimalIndex)
            );
          }
        }
      }

      if (typeof number.value === "number") {
        onNumberChange(number.value);
        return;
      }

      onNumberChange(null);
    },
    { immediate: true }
  );

  const onKeyPress = (event: KeyboardEvent) => {
    // Prevent minus sign
    if (min.value !== null && min.value >= 0 && event.key === "-") {
      event.preventDefault();
    }

    // Prevent plus sign
    if (event.key === "+") {
      event.preventDefault();
    }

    const decimalSeparator = getDecimalSeparator();

    // Prevent decimal point
    if (!isDecimal.value && event.key === decimalSeparator) {
      event.preventDefault();
    }

    // Prevent thousands separator
    if (decimalSeparator === "." && event.key === ",") {
      event.preventDefault();
    } else if (decimalSeparator === "," && event.key === ".") {
      event.preventDefault();
    }
  };

  const rules = computed(() => {
    return [...(extraRules?.value ?? [])];
  });

  return {
    number,
    onKeyPress,
    rules,
  };
}

function getDecimalSeparator() {
  let decSep = ".";

  try {
    const sep = parseFloat(String(3 / 2))
      .toLocaleString()
      .substring(1, 2);
    if (sep === "." || sep === ",") {
      decSep = sep;
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return decSep;
}
