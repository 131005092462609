import { computed, ref, watch } from "vue";

import { fetchTestDevicesCount } from "@/api/testdevices";
import { ProductLine } from "@/lib/productLine";
import { ReadonlyRef } from "@/lib/typing";

interface UseTestDevicesOptions {
  productLine: ReadonlyRef<ProductLine | null>;
}

export type UseTestDevices = typeof useTestDevices;

export const useTestDevices = ({ productLine }: UseTestDevicesOptions) => {
  const hasUserTestDevices = ref(false);
  watch(
    [productLine],
    async () => {
      if (productLine.value === null) {
        hasUserTestDevices.value = false;
        return;
      }

      const testDevicesCount = await fetchTestDevicesCount({
        product_line: String(productLine.value.id),
      });

      hasUserTestDevices.value = testDevicesCount > 0;
    },
    { immediate: true }
  );

  return {
    hasUserTestDevices: computed(() => hasUserTestDevices.value),
  };
};
