import useQuasar from 'quasar/src/composables/use-quasar.js';;

import MessageCancelDialog from "./MessageCancelDialog.vue";

interface MessageCancelDialogOptions {
  onDismiss: () => void;
  onOk: (rationale: string | null) => void;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useMessageCancelDialog(options: MessageCancelDialogOptions) {
  const $q = useQuasar();
  const openDialog = () => {
    $q.dialog({
      component: MessageCancelDialog,
    })
      .onDismiss(() => options.onDismiss())
      .onOk((payload: unknown) => {
        const rationale = (payload as { rationale?: unknown }).rationale;
        options.onOk(typeof rationale === "string" ? rationale : null);
      });
  };

  return {
    openDialog,
  };
}
