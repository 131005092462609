import { Tag } from "@/api/tags/typedefs";
import { httpClient, throwIfBadResponse } from "@/lib/http";

import { buildDeliveryOptionsQueryParams } from "./create";
import { convertPushMessage } from "./details";
import { ExistingPushMessage } from "./typedefs";

export async function updatePushMessage(
  productLine: number,
  pushMessage: ExistingPushMessage
): Promise<ExistingPushMessage> {
  const response = await httpClient.put(
    `/api/pushnotifications/product-lines/${productLine}/messages/${pushMessage.id}/`,
    {
      id: pushMessage.id,
      url: pushMessage.url ? pushMessage.url : null,
      campaign_id: pushMessage?.campaignId ?? null,
      name: pushMessage.name,
      title: pushMessage.title,
      message: pushMessage.text,
      image: pushMessage.image?.id ?? null,
      dry_run: pushMessage.isDryRun,
      delivery_type: pushMessage.deliveryType,
      target: pushMessage.target?.id ?? null,
      ...buildDeliveryOptionsQueryParams(
        pushMessage.deliveryType,
        pushMessage.deliveryOptions
      ),
      tags: pushMessage.tags.map((tag: Tag) => tag.id),
    }
  );
  await throwIfBadResponse(response);

  const data = await response.json();

  return convertPushMessage(data);
}
