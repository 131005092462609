import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { toRefs } from "vue";

import { PushMessage } from "@/api/pushnotifications/pushmessage/typedefs";
import { ValidationStatus } from "@/api/target/typedefs";
import FormAction from "@/components/shared/formaction/FormAction.vue";

import {
  AllowedActionButtons,
  useMessageFormActions,
} from "./useMessageFormActions";


export default /*@__PURE__*/_defineComponent({
  __name: 'MessageFormActions',
  props: {
    message: {},
    validationStatus: {},
    isLoading: { type: Boolean },
    isEditingActivePushMessage: { type: Boolean },
    isClone: { type: Boolean },
    hasUnsavedChanges: { type: Boolean },
    hasUserTestDevice: { type: Boolean },
    allowedActionButtons: {},
    hasDryRunPermissions: { type: Boolean }
  },
  emits: ["preview", "validate", "clone", "activate", "cancel", "delete", "reset", "save", "edit"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const {
  resetAction,
  previewAction,
  saveAction,
  validateAction,
  cloneAction,
  deleteAction,
  activateAction,
  cancelAction,
  editAction,
} = useMessageFormActions({ ...toRefs(props) });

return (_ctx: any,_cache: any) => {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(FormAction, {
      "data-test": "pm.action.preview",
      config: _unref(previewAction),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('preview')))
    }, {
      default: _withCtx(() => _cache[9] || (_cache[9] = [
        _createTextVNode("Preview")
      ])),
      _: 1
    }, 8, ["config"]),
    _createVNode(FormAction, {
      "data-test": "pm.action.validate",
      config: _unref(validateAction),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('validate')))
    }, {
      default: _withCtx(() => _cache[10] || (_cache[10] = [
        _createTextVNode("Validate")
      ])),
      _: 1
    }, 8, ["config"]),
    _createVNode(FormAction, {
      "data-test": "pm.action.clone",
      config: _unref(cloneAction),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('clone')))
    }, {
      default: _withCtx(() => _cache[11] || (_cache[11] = [
        _createTextVNode("Clone")
      ])),
      _: 1
    }, 8, ["config"]),
    _createVNode(FormAction, {
      "data-test": "pm.action.activate",
      config: _unref(activateAction),
      onClick: _cache[3] || (_cache[3] = ($event: any) => (emit('activate')))
    }, {
      default: _withCtx(() => _cache[12] || (_cache[12] = [
        _createTextVNode("Activate")
      ])),
      _: 1
    }, 8, ["config"]),
    _createVNode(FormAction, {
      "data-test": "pm.action.cancel",
      config: _unref(cancelAction),
      onClick: _cache[4] || (_cache[4] = ($event: any) => (emit('cancel')))
    }, {
      default: _withCtx(() => _cache[13] || (_cache[13] = [
        _createTextVNode("Cancel")
      ])),
      _: 1
    }, 8, ["config"]),
    _createVNode(FormAction, {
      "data-test": "pm.action.delete",
      config: _unref(deleteAction),
      onClick: _cache[5] || (_cache[5] = ($event: any) => (emit('delete')))
    }, {
      default: _withCtx(() => _cache[14] || (_cache[14] = [
        _createTextVNode("Delete")
      ])),
      _: 1
    }, 8, ["config"]),
    _createVNode(FormAction, {
      "data-test": "pm.action.reset",
      config: _unref(resetAction),
      onClick: _cache[6] || (_cache[6] = ($event: any) => (emit('reset')))
    }, {
      default: _withCtx(() => _cache[15] || (_cache[15] = [
        _createTextVNode("Reset")
      ])),
      _: 1
    }, 8, ["config"]),
    _createVNode(FormAction, {
      "data-test": "pm.action.edit",
      config: _unref(editAction),
      onClick: _cache[7] || (_cache[7] = ($event: any) => (emit('edit')))
    }, {
      default: _withCtx(() => [
        _cache[17] || (_cache[17] = _createTextVNode(" Edit ")),
        _createVNode(_component_q_tooltip, { delay: 400 }, {
          default: _withCtx(() => _cache[16] || (_cache[16] = [
            _createTextVNode(" You're going to edit already active Push Notification ")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["config"]),
    _createVNode(FormAction, {
      "data-test": "pm.action.save",
      config: _unref(saveAction),
      onClick: _cache[8] || (_cache[8] = ($event: any) => (emit('save')))
    }, {
      default: _withCtx(() => _cache[18] || (_cache[18] = [
        _createTextVNode("Save")
      ])),
      _: 1
    }, 8, ["config"])
  ], 64))
}
}

})