import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row items-center justify-end q-pb-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_field = _resolveComponent("q-field")!
  const _component_time_input = _resolveComponent("time-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_input, _mergeProps({
    ref: "inputRef",
    modelValue: _ctx.inputText,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.inputText) = $event))
  }, _ctx.$attrs, {
    dense: _ctx.dense,
    label: _ctx.label,
    hint: _ctx.hint,
    disable: _ctx.disable,
    filled: "",
    mask: "####-##-## ##:##",
    "data-test": "datetimeinput.input",
    rules: _ctx.rules,
    onChange: _ctx.onInputTextChange,
    onClear: _ctx.onInputTextChange
  }), {
    append: _withCtx(() => [
      _createVNode(_component_q_icon, {
        name: "event",
        class: "cursor-pointer"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_popup_proxy, {
            ref: "popupRef",
            "transition-show": "scale",
            "transition-hide": "scale"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_form, {
                    ref: "formRef",
                    greedy: "",
                    onSubmit: _ctx.onFormSubmit
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_field, {
                        modelValue: _ctx.date,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.date) = $event)),
                        rules: _ctx.dateValidationRules
                      }, {
                        control: _withCtx(() => [
                          _createVNode(_component_q_date, {
                            modelValue: _ctx.date,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
                            mask: "YYYY-MM-DD",
                            flat: ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "rules"]),
                      _createVNode(_component_time_input, {
                        modelValue: _ctx.time,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.time) = $event))
                      }, null, 8, ["modelValue"]),
                      _createElementVNode("div", _hoisted_1, [
                        _withDirectives(_createVNode(_component_q_btn, {
                          label: "Cancel",
                          color: "secondary",
                          flat: ""
                        }, null, 512), [
                          [_directive_close_popup]
                        ]),
                        _createVNode(_component_q_btn, {
                          label: "Save",
                          color: "primary",
                          flat: "",
                          type: "submit"
                        })
                      ])
                    ]),
                    _: 1
                  }, 8, ["onSubmit"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 16, ["modelValue", "dense", "label", "hint", "disable", "rules", "onChange", "onClear"]))
}