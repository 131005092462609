import { PushMessage } from "@/api/pushnotifications/pushmessage/typedefs";
import { isValidMessageUrl } from "@/lib/url";
import {
  PushMessageQueryValues,
  UsePushMessageQueryParams,
} from "@/views/pushmessages/typedefs";

export const usePushMessageQueryParams: UsePushMessageQueryParams = ({
  router,
}) => {
  const mapQueryParamsToPushMessageFields = (): PushMessageQueryValues => {
    const result: PushMessageQueryValues = {};
    const queryParams = { ...router.currentRoute.value.query };
    const queryParamUrl = queryParams?.url ?? null;
    const queryParamCampaignId = queryParams?.campaign_id;

    if (queryParamUrl && typeof queryParamUrl === "string") {
      const decodedUrl = decodeURIComponent(queryParamUrl);
      if (isValidMessageUrl(decodedUrl)) {
        result.url = decodedUrl;
      } else {
        delete queryParams.url;
      }
    }

    if (queryParamCampaignId) {
      if (Number.isInteger(Number(queryParamCampaignId))) {
        result.campaignId = Number(queryParamCampaignId);
      } else {
        delete queryParams.campaign_id;
      }
    }

    router.push({ query: queryParams });

    return result;
  };

  const mapPushMessageFieldsToQueryParams = (
    pushMessage: PushMessage
  ): Record<string, string> => {
    const queryParams: Record<string, string> = {};
    if (pushMessage.url && isValidMessageUrl(pushMessage.url)) {
      queryParams.url = encodeURIComponent(pushMessage.url);
    }
    if (pushMessage.campaignId !== null) {
      queryParams.campaign_id = String(pushMessage.campaignId);
    }
    router.push({
      query: { ...router.currentRoute.value.query, ...queryParams },
    });
    return queryParams;
  };

  return {
    mapQueryParamsToPushMessageFields,
    mapPushMessageFieldsToQueryParams,
  };
};
