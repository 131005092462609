
import { computed, defineComponent, PropType } from "vue";

import { DeliveryOptionsScheduled } from "@/api/pushnotifications/messagedelivery/typedefs";
import DateTimeInput from "@/components/shared/DateTimeInput.vue";

export default defineComponent({
  components: {
    DateTimeInput,
  },
  props: {
    modelValue: {
      type: null as unknown as PropType<DeliveryOptionsScheduled | null>,
      required: true,
      validator: (v: unknown) => v === null || typeof v === "object",
    },
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: {
    "update:modelValue": (payload: DeliveryOptionsScheduled | null) =>
      payload === null || typeof payload.dateTime === "string",
  },
  setup(props, { emit }) {
    const dateTime = computed(() => props.modelValue?.dateTime ?? null);

    const onDateTimeChange = (newValue: string | null) => {
      if (newValue === null) {
        emit("update:modelValue", null);
        return;
      }
      emit("update:modelValue", { dateTime: newValue });
    };
    return {
      dateTime,
      onDateTimeChange,
    };
  },
});
