import { useConfigData } from "@/composables/useConfigData";
import { useTestDevices } from "@/composables/useTestDevices";

import { usePushMessageActions } from "./usePushMessageActions";
import { usePushMessageChangesWatcher } from "./usePushMessageChangesWatcher";
import { usePushMessagePermissions } from "./usePushMessagePermissions";
import { usePushMessageQueryParams } from "./usePushMessageQueryParams";
import { usePushMessageRouteParams } from "./usePushMessageRouteParams";
import { usePushMessageStore } from "./usePushMessageStore";
import { usePushMessageValidation } from "./usePushMessageValidation";

/** Injects dependencies required by usePushMessage composable.*/
export function usePushMessageDependencyInjector() {
  return {
    usePushMessageActions,
    usePushMessageValidation,
    usePushMessagePermissions,
    usePushMessageRouteParams,
    usePushMessageQueryParams,
    usePushMessageChangesWatcher,
    useTestDevices,
    useConfigData,
    usePushMessageStore,
  };
}
