import { Composable } from "@/lib/typing";

interface UseTargetSelectOptionReturn {
  getIsRuntimeIcon: (isRuntime: boolean | null) => string;
  getIsRuntimeTooltip: (isRuntime: boolean | null) => string;
  getIsRuntimeColor: (isRuntime: boolean | null) => string;
  getAffectedUsersColor: (affectedUsersNumber: number | null) => string;
  getAffectedUsersTooltip: (affectedUsersNumber: number | null) => string;
}
export const useTargetSelectOption: Composable<
  void,
  UseTargetSelectOptionReturn
> = () => {
  function getIsRuntimeIcon(isRuntime: boolean | null): string {
    return isRuntime ? "hourglass_empty" : "hourglass_disabled";
  }

  function getIsRuntimeTooltip(isRuntime: boolean | null): string {
    return isRuntime ? "runtime" : "non runtime";
  }

  function getIsRuntimeColor(isRuntime: boolean | null): string {
    if (isRuntime === null) {
      return "grey";
    }
    return "black";
  }

  function getAffectedUsersColor(affectedUsersNumber: number | null): string {
    if (affectedUsersNumber === null) {
      return "grey";
    }
    return "black";
  }

  function getAffectedUsersTooltip(affectedUsersNumber: number | null): string {
    if (affectedUsersNumber === null) {
      return "affected users (not validated?)";
    }
    return "affected users";
  }

  return {
    getIsRuntimeIcon,
    getIsRuntimeTooltip,
    getIsRuntimeColor,
    getAffectedUsersColor,
    getAffectedUsersTooltip,
  };
};
