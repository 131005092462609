import { httpClient, throwIfBadResponse } from "@/lib/http";

export async function cancelPushMessage(
  productLine: number,
  id: number,
  reason: string
): Promise<void> {
  const response = await httpClient.post(
    `/api/pushnotifications/message/cancel/${productLine}`,
    {
      id,
      reason,
    }
  );

  await throwIfBadResponse(response);
}
