
import { computed, defineComponent, PropType, toRef } from "vue";

import { DateRange, isValidDateRange } from "@/lib/dateTime";

import { useDateRangeInput } from "./useDateRangeInput";
import { useInputProps } from "./useInputProps";

export default defineComponent({
  props: {
    ...useInputProps(),
    modelValue: {
      required: true,
      type: null as unknown as PropType<DateRange | null>,
      validator: (v: unknown) => v === null || isValidDateRange(v as DateRange),
    },
  },
  emits: {
    "update:modelValue": (v: DateRange | null) =>
      v === null || isValidDateRange(v as DateRange),
  },
  setup(props, { emit }) {
    return useDateRangeInput({
      modelValue: toRef(props, "modelValue"),
      onRangeChange: (value) => emit("update:modelValue", value),
      extraRules: computed(() => props.extraRules),
    });
  },
});
