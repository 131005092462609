import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createBlock(_component_q_input, {
    modelValue: _ctx.number,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.number) = $event)),
    modelModifiers: { number: true },
    disable: _ctx.disable,
    type: "number",
    min: _ctx.min,
    max: _ctx.max,
    label: _ctx.label,
    dense: _ctx.dense,
    hint: _ctx.hint,
    rules: _ctx.extraRules,
    onKeypress: _ctx.onKeyPress
  }, null, 8, ["modelValue", "disable", "min", "max", "label", "dense", "hint", "rules", "onKeypress"]))
}