import { computed, Ref } from "vue";

import { DeliveryType } from "@/api/pushnotifications/messagedelivery/typedefs";
import {
  isExistingPushMessage,
  PushMessage,
} from "@/api/pushnotifications/pushmessage/typedefs";
import { ValidationStatus } from "@/api/target/typedefs";
import { ActionConfiguration } from "@/components/shared/formaction/typedefs";
import { ReadonlyComputedRef } from "@/lib/typing";

interface MessageFormActionsOptions {
  message: Ref<PushMessage | null>;
  isLoading: Ref<boolean>;
  isEditingActivePushMessage: Ref<boolean>;
  isClone: Ref<boolean>;
  hasUnsavedChanges: Ref<boolean>;
  validationStatus: Ref<ValidationStatus | null>;
  hasUserTestDevice: Ref<boolean>;
  hasDryRunPermissions: Ref<boolean>;
  allowedActionButtons: Ref<AllowedActionButtons>;
}

export interface AllowedActionButtons {
  saveNew: boolean;
  saveExisting: boolean;
  validate: boolean;
  clone: boolean;
  delete: boolean;
  activate: boolean;
  cancel: boolean;
  edit: boolean;
}

export function useMessageFormActions({
  message,
  isLoading,
  isEditingActivePushMessage,
  isClone,
  validationStatus,
  hasUnsavedChanges,
  hasUserTestDevice,
  hasDryRunPermissions,
  allowedActionButtons,
}: MessageFormActionsOptions) {
  const messageStatus = computed(() => {
    if (message.value !== null && isExistingPushMessage(message.value)) {
      return message.value.status;
    }
    return null;
  });

  const hasTarget = computed(() => Boolean(message.value?.target));

  const isExistingMessageEdit = computed(
    () => (message.value && isExistingPushMessage(message.value)) || false
  );

  const previewAction: ReadonlyComputedRef<ActionConfiguration> = computed(
    () => {
      if (!hasUserTestDevice.value) {
        return {
          visible: true,
          disabled: true,
          tooltip: "User needs to register a test device for this product line",
        };
      } else if (message.value?.text === "") {
        return {
          visible: true,
          disabled: true,
          tooltip: "Message with empty text cannot be previewed.",
        };
      } else {
        return {
          visible: true,
          disabled: isLoading.value,
        };
      }
    }
  );

  const saveAction: ReadonlyComputedRef<ActionConfiguration> = computed(() => {
    const messageRequiresSave = isClone.value || hasUnsavedChanges.value;

    const getTooltipMessage = (): string | undefined => {
      if (message.value?.isDryRun && !hasDryRunPermissions.value) {
        return "You don't have permissions to save message with dry run on.";
      } else if (
        !message.value?.title ||
        !message.value?.text ||
        !message.value?.name
      ) {
        return "Message must have non empty name, title & text.";
      } else if (isEditingActivePushMessage.value && !messageRequiresSave) {
        return "No changes";
      }
      return undefined;
    };

    const isVisible = (): boolean => {
      // Only draft or active PNs can be edited
      if (
        isExistingMessageEdit.value &&
        !(messageStatus.value === "Draft" || isEditingActivePushMessage.value)
      ) {
        return false;
      }
      // saveExisting perm required to edit existing PN
      if (
        isExistingMessageEdit.value &&
        !allowedActionButtons.value.saveExisting
      ) {
        return false;
      }
      // saveNew perm required to add new PN
      if (!isExistingMessageEdit.value && !allowedActionButtons.value.saveNew) {
        return false;
      }
      return (
        messageRequiresSave ||
        isEditingActivePushMessage.value ||
        !isExistingMessageEdit.value
      );
    };

    const tooltipMessage = getTooltipMessage();
    return {
      visible: isVisible(),
      color: "primary",
      loading: isLoading.value,
      disabled: Boolean(tooltipMessage),
      tooltip: tooltipMessage,
    };
  });

  const resetAction: ReadonlyComputedRef<ActionConfiguration> = computed(() => {
    if (
      messageStatus.value === "Draft" &&
      allowedActionButtons.value.saveExisting
    ) {
      return {
        visible: hasUnsavedChanges.value,
        disabled: isLoading.value,
      };
    } else if (
      !isExistingMessageEdit.value &&
      allowedActionButtons.value.saveNew
    ) {
      return {
        visible: hasUnsavedChanges.value,
        disabled: isLoading.value,
      };
    }
    return { visible: false };
  });

  const validateAction: ReadonlyComputedRef<ActionConfiguration> = computed(
    () => {
      const color = "secondary";
      if (validationStatus.value === ValidationStatus.VALIDATING) {
        return {
          visible: allowedActionButtons.value.validate,
          loading: true,
          tooltip: "Validation is in progress",
          color,
        };
      } else if (
        messageStatus.value !== "Draft" ||
        validationStatus.value !== ValidationStatus.NOT_VALIDATED
      ) {
        return {
          visible: false,
        };
      } else if (hasUnsavedChanges.value) {
        return {
          visible: allowedActionButtons.value.validate,
          disabled: true,
          tooltip: "Message with unsaved changes cannot be validated",
          color,
        };
      } else if (!hasTarget.value) {
        return {
          visible: allowedActionButtons.value.validate,
          disabled: true,
          tooltip: "Message without target cannot be validated",
          color,
        };
      }

      return {
        visible: allowedActionButtons.value.validate,
        disabled: isLoading.value,
        color,
      };
    }
  );

  const cloneAction: ReadonlyComputedRef<ActionConfiguration> = computed(() => {
    return {
      visible: isExistingMessageEdit.value && allowedActionButtons.value.clone,
      disabled: isLoading.value,
      color: "info",
    };
  });

  const deleteAction: ReadonlyComputedRef<ActionConfiguration> = computed(
    () => {
      if (!isExistingMessageEdit.value || messageStatus.value !== "Draft") {
        return { visible: false };
      }

      return {
        visible: allowedActionButtons.value.delete,
        disabled: isLoading.value,
        color: "negative",
      };
    }
  );

  const activateAction: ReadonlyComputedRef<ActionConfiguration> = computed(
    () => {
      if (
        messageStatus.value !== "Draft" ||
        validationStatus.value !== ValidationStatus.VALIDATED ||
        !hasTarget.value ||
        hasUnsavedChanges.value
      ) {
        return { visible: false };
      }

      return {
        visible: allowedActionButtons.value.activate,
        disabled: isLoading.value,
        color: "primary",
      };
    }
  );

  const cancelAction: ReadonlyComputedRef<ActionConfiguration> = computed(
    () => {
      if (messageStatus.value !== "Active") {
        return { visible: false };
      }

      return {
        visible: allowedActionButtons.value.cancel,
        disabled: isLoading.value,
        color: "negative",
      };
    }
  );

  const editAction: ReadonlyComputedRef<ActionConfiguration> = computed(() => {
    if (
      messageStatus.value !== "Active" ||
      isEditingActivePushMessage.value ||
      message.value?.deliveryType === DeliveryType.TRIGGERED
    ) {
      return { visible: false };
    }

    return {
      visible: allowedActionButtons.value.edit,
      disabled: isLoading.value,
      color: "warning",
    };
  });

  return {
    resetAction,
    previewAction,
    saveAction,
    validateAction,
    cloneAction,
    deleteAction,
    activateAction,
    cancelAction,
    editAction,
  };
}
