import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "message" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "heading" }
const _hoisted_5 = {
  key: 1,
  class: "title-container"
}
const _hoisted_6 = { class: "title" }
const _hoisted_7 = {
  key: 0,
  class: "message-content"
}
const _hoisted_8 = ["src"]

import { computed } from "vue";

import { ImageMetadata } from "@/api/image/typedefs";
import messagePreviewBackgroundUrl from "@/assets/images/message-preview-background.png";
import { ProductLine } from "@/lib/productLine";

import VisualPreview from "../shared/visualpreview/VisualPreview.vue";
import { useMessageFormVisualPreview } from "./useMessageFormVisualPreview";


export default /*@__PURE__*/_defineComponent({
  __name: 'MessageFormVisualPreview',
  props: {
    productLine: {},
    title: {},
    content: {},
    image: {}
  },
  setup(__props: any) {

const props = __props;

const { heading, iconUrl } = useMessageFormVisualPreview({
  productLine: computed(() => props.productLine),
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(VisualPreview, {
    "is-expandable": true,
    "background-url": _unref(messagePreviewBackgroundUrl)
  }, {
    default: _withCtx(({ isExpanded }) => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.productLine !== null)
          ? (_openBlock(), _createElementBlock("header", _hoisted_2, [
              _createElementVNode("img", {
                src: _unref(iconUrl) ?? undefined
              }, null, 8, _hoisted_3),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(heading)), 1),
              _createElementVNode("span", {
                class: _normalizeClass(["expand", { expanded: isExpanded }])
              }, null, 2)
            ]))
          : _createCommentVNode("", true),
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.title), 1)
            ]))
          : _createCommentVNode("", true),
        isExpanded
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              (_ctx.content)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.content), 1))
                : _createCommentVNode("", true),
              (_ctx.image)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    class: "image",
                    src: _ctx.image.url
                  }, null, 8, _hoisted_8))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["background-url"]))
}
}

})