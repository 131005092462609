import useQuasar from 'quasar/src/composables/use-quasar.js';;

import MessageDeleteDialog from "./MessageDeleteDialog.vue";

interface MessageDeleteDialogOptions {
  onDismiss: () => void;
  onOk: (rationale: string | null) => void;
}

export function useMessageDeleteDialog(options: MessageDeleteDialogOptions) {
  const $q = useQuasar();
  const openDialog = () => {
    $q.dialog({
      component: MessageDeleteDialog,
    })
      .onDismiss(() => options.onDismiss())
      .onOk((payload: unknown) => {
        const rationale = (payload as { rationale?: unknown }).rationale;
        options.onOk(typeof rationale === "string" ? rationale : null);
      });
  };

  return {
    openDialog,
  };
}
