
import QInput from 'quasar/src/components/input/QInput.js';import useFormChild from 'quasar/src/composables/use-form-child.js';;
import { defineComponent, PropType, Ref, ref, watch } from "vue";

import { DeliveryOptionsRecurring } from "@/api/pushnotifications/messagedelivery/typedefs";
import DateRangeInput from "@/components/shared/DateRangeInput.vue";
import TimeInput from "@/components/shared/TimeInput.vue";
import { DateRange } from "@/lib/dateTime";

export default defineComponent({
  components: {
    TimeInput,
    DateRangeInput,
  },
  props: {
    modelValue: {
      type: null as unknown as PropType<DeliveryOptionsRecurring | null>,
      required: true,
      validator: (v: unknown) => v === null || typeof v === "object",
    },
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: {
    "update:modelValue": (v: DeliveryOptionsRecurring) => typeof v === "object",
  },
  setup(props, { emit }) {
    const time: Ref<string | null> = ref(props.modelValue?.time ?? null);
    const interval: Ref<number | null> = ref(
      props.modelValue?.interval ?? null
    );
    const dateRange: Ref<DateRange | null> = ref(
      props.modelValue?.dateRange ?? null
    );

    const emptyStringToNull = (arg: number | "" | null) => {
      if (arg === "") {
        return null;
      }
      return arg;
    };

    watch([time, interval, dateRange], () => {
      emit("update:modelValue", {
        time: time.value ?? "",
        interval: emptyStringToNull(interval.value),
        dateRange: dateRange.value ?? { from: "", to: "" },
      });
    });

    watch(
      () => props.modelValue,
      () => {
        if (props.modelValue === null) return;

        if (time.value !== props.modelValue?.time) {
          time.value = props.modelValue?.time ?? null;
        }

        if (interval.value !== props.modelValue?.interval) {
          interval.value = props.modelValue?.interval ?? null;
        }

        if (
          dateRange.value?.from !== props.modelValue?.dateRange.from ||
          dateRange.value?.to !== props.modelValue?.dateRange?.to
        ) {
          dateRange.value = props.modelValue?.dateRange ?? null;
        }
      }
    );

    const intervalInputRef: Ref<QInput | null> = ref(null);

    useFormChild({
      validate: () => intervalInputRef.value?.validate?.() ?? false,
      resetValidation: () => intervalInputRef.value?.resetValidation(),
      requiresQForm: false,
    });

    return {
      time,
      interval,
      dateRange,
      intervalInputRef,
    };
  },
});
