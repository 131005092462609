import { buildErrorMessage } from "@/lib/errors";
import { httpClient } from "@/lib/http";

import { convertImage } from "./converters";
import { ImageMetadata } from "./typedefs";

export async function uploadImage(
  imageConstraintNames: string[],
  file: File
): Promise<ImageMetadata> {
  const queryParams = new URLSearchParams({
    constraints: imageConstraintNames.join(","),
  }).toString();
  const formData = new FormData();
  formData.set("file", file);
  const response = await httpClient.post(
    `/api/image-upload?${queryParams}`,
    formData
  );
  const body = await response.json();

  if (!response.ok) {
    throw new Error(
      buildErrorMessage(response.status, response.statusText, body)
    );
  }

  return convertImage(body);
}
