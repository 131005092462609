import { httpClient, throwIfBadResponse } from "@/lib/http";

export async function deletePushMessage(
  id: number,
  productLineId: number,
  reason: string
): Promise<void> {
  const response = await httpClient.post(
    `/api/pushnotifications/message/delete/${productLineId}`,
    {
      id,
      reason,
    }
  );

  await throwIfBadResponse(response);
}
