
import { defineComponent, PropType } from "vue";

import { ProductLine } from "@/lib/productLine";

export default defineComponent({
  props: {
    productLine: {
      type: null as unknown as PropType<ProductLine | null>,
      required: true,
      validator: (v: unknown) => v === null || typeof v === "object",
    },
  },
});
