import QInput from 'quasar/src/components/input/QInput.js';import useFormChild from 'quasar/src/composables/use-form-child.js';;
import { computed, ref, Ref, watch } from "vue";

import {
  DateRange,
  dateRangeFromString,
  isDateRangeStringStartLessOrEqualEnd,
  isDateRangeStringValidFormat,
  isValidDateRange,
} from "@/lib/dateTime";
import { ValidationRule } from "@/lib/rules/typedefs";

interface DateRangeInputOptions {
  modelValue: Ref<DateRange | null>;
  onRangeChange: (arg: DateRange | null) => void;
  extraRules?: Ref<ValidationRule<string | null>[]>;
}

const DEFAULT_RULES = [
  (val: string | null) =>
    val === null ||
    val === "" ||
    isDateRangeStringValidFormat(val) ||
    "This is not a valid date range",
  (val: string | null) =>
    val === null ||
    val === "" ||
    isDateRangeStringStartLessOrEqualEnd(val) ||
    "Start date cannot be later than end date.",
];

export function useDateRangeInput({
  modelValue,
  onRangeChange,
  extraRules,
}: DateRangeInputOptions) {
  const dateRange: Ref<{ from: string; to: string } | null> = ref(null);
  const inputText: Ref<string | null> = ref(null);

  watch(
    [modelValue],
    () => {
      if (modelValue.value !== null && isValidDateRange(modelValue.value)) {
        dateRange.value = modelValue.value;
        const { from, to } = modelValue.value;
        inputText.value = `${from} - ${to}`;
      }
    },
    { immediate: true }
  );

  watch([dateRange], () => {
    if (dateRange.value !== null) {
      inputText.value = `${dateRange.value.from} - ${dateRange.value.to}`;
      if (isValidDateRange(dateRange.value)) {
        onRangeChange(dateRange.value);
      }
    } else if (inputText.value !== null) {
      inputText.value = null;
      onRangeChange(null);
    }
  });

  const onInputTextChange = () => {
    if (!inputText.value) {
      onRangeChange(null);
      return;
    }

    const isValidFormat = isDateRangeStringValidFormat(inputText.value);
    const isStartLessThanEnd = isDateRangeStringStartLessOrEqualEnd(
      inputText.value
    );
    if (!isValidFormat || !isStartLessThanEnd) {
      return;
    }

    const range = dateRangeFromString(inputText.value);
    if (range === null) return;

    onRangeChange(range);
  };

  const rules = computed(() => {
    return [...DEFAULT_RULES, ...(extraRules?.value ?? [])];
  });

  const inputRef: Ref<QInput | null> = ref(null);

  useFormChild({
    validate: () => inputRef.value?.validate?.() ?? false,
    resetValidation: () => inputRef.value?.resetValidation?.(),
    requiresQForm: false,
  });

  return {
    inputRef,
    rules,
    dateRange,
    inputText,
    onInputTextChange,
  };
}

export const TEST_ONLY = { DEFAULT_RULES };
