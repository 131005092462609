import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import QSelect from 'quasar/src/components/select/QSelect.js';;
import { Ref, ref } from "vue";

import { useSegmentSelect } from "@/components/shared/segment/useSegmentSelect";
import { ProductLine } from "@/lib/productLine";
import { OnFilter, OnFilterAbortFn, OnFilterDoneFn } from "@/lib/quasar";


export default /*@__PURE__*/_defineComponent({
  __name: 'SegmentSelect',
  props: {
    productLine: {},
    modelValue: {},
    segmentName: {},
    staticList: {}
  },
  setup(__props: any) {

const props = __props;

const selectRef: Ref<QSelect | null> = ref(null);

const onScroll = async ({ to, ref }: { to: number; ref: QSelect }) => {
  await fetchMoreSegmentOptions({ to, ref });
};

const onFilter: OnFilter = async (
  inputValue: string,
  doneFn: OnFilterDoneFn,
  abortFn: OnFilterAbortFn
) => {
  await fetchSegmentOptions({
    value: inputValue,
    onSuccess: () => doneFn(() => undefined),
    onError: abortFn,
  });
};

const { fetchSegmentOptions, fetchMoreSegmentOptions, segmentOptions } =
  useSegmentSelect({
    segmentName: props.segmentName || "",
    productLine: props.productLine,
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (props.staticList)
      ? (_openBlock(), _createBlock(_unref(QSelect), _mergeProps({ key: 0 }, _ctx.$attrs, {
          ref_key: "selectRef",
          ref: selectRef,
          "model-value": props.modelValue,
          filled: "",
          "use-input": "",
          "emit-value": "",
          options: props.staticList
        }), null, 16, ["model-value", "options"]))
      : _createCommentVNode("", true),
    (props.segmentName)
      ? (_openBlock(), _createBlock(_unref(QSelect), _mergeProps({ key: 1 }, _ctx.$attrs, {
          ref_key: "selectRef",
          ref: selectRef,
          "model-value": props.modelValue,
          filled: "",
          "use-input": "",
          "emit-value": "",
          options: _unref(segmentOptions),
          onFilter: onFilter,
          onVirtualScroll: onScroll
        }), null, 16, ["model-value", "options"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})