
import { defineComponent, PropType, toRef } from "vue";

import { useNumberInput } from "@/components/shared/useNumberInput";

import { useInputProps } from "./useInputProps";

export default defineComponent({
  props: {
    ...useInputProps(),
    modelValue: {
      type: null as unknown as PropType<number | null>,
      required: true,
      validator: (v: unknown) => typeof v === "number" || v === null,
    },
    min: {
      type: null as unknown as PropType<number | string | null>,
      default: null,
    },
    max: {
      type: null as unknown as PropType<number | string | null>,
      default: null,
    },
    isDecimal: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:modelValue": (v: number | null) =>
      typeof v === "number" || v === null,
  },
  setup(props, { emit }) {
    return useNumberInput({
      modelValue: toRef(props, "modelValue"),
      onNumberChange: (value) => emit("update:modelValue", value),
      min: toRef(props, "min"),
      max: toRef(props, "max"),
      isDecimal: toRef(props, "isDecimal"),
    });
  },
});
