import { cloneDeep } from "lodash";
import { defineStore } from "pinia";
import { computed, Ref, ref } from "vue";

import {
  ExistingPushMessage,
  NewPushMessage,
  PushMessage,
} from "@/api/pushnotifications/pushmessage/typedefs";
import { Tag } from "@/api/tags/typedefs";
import { ConfigDataComposable } from "@/composables/useConfigData";
import { ReadonlyComputedRef } from "@/lib/typing";

import { PushMessageQueryValues } from "./typedefs";

interface CloneOptions {
  sourcePushMessage: ExistingPushMessage;
  isProductLineMatch: boolean;
}

export function buildPushMessageFromClone(
  defaultTags?: Tag[],
  cloneOptions?: CloneOptions
): NewPushMessage {
  // As targets are per product line - clone target only if product line match.
  const target = cloneOptions?.isProductLineMatch
    ? cloneOptions?.sourcePushMessage?.target
    : null;

  const sourcePushMessage = cloneOptions?.sourcePushMessage;
  // TODO(PNS-1648): Check if source message name is also a clone.
  const name = sourcePushMessage
    ? `${sourcePushMessage.name} (cloned from message with ID ${sourcePushMessage.id})`
    : "";

  const campaignId = null; // Do not clone campaign ID.

  return {
    name,
    title: sourcePushMessage?.title ?? "",
    text: sourcePushMessage?.text ?? "",
    url: sourcePushMessage?.url ?? null,
    campaignId,
    image: sourcePushMessage?.image ?? null,
    target,
    deliveryType: sourcePushMessage?.deliveryType ?? null,
    deliveryOptions: sourcePushMessage?.deliveryOptions ?? {},
    isDryRun: sourcePushMessage?.isDryRun ?? false,
    tags: sourcePushMessage?.tags ?? defaultTags ?? [],
  };
}

export function buildNewPushMessage(
  parsedQueryParams?: PushMessageQueryValues,
  defaultTags?: Tag[],
  cloneOptions?: CloneOptions
): NewPushMessage {
  const newPushMessage = buildPushMessageFromClone(defaultTags, cloneOptions);
  newPushMessage.url = parsedQueryParams?.url ?? newPushMessage.url;
  newPushMessage.campaignId =
    parsedQueryParams?.campaignId ?? newPushMessage.campaignId;

  return newPushMessage;
}

export const usePushMessageStore = defineStore("pushMessage", () => {
  // state
  const pushMessageRef: Ref<PushMessage> = ref<PushMessage>(
    buildNewPushMessage()
  );
  const notEditedPushMessageRef = ref(cloneDeep(pushMessageRef.value));
  const isCloneRef = ref(false);

  // getters
  const pushMessage: ReadonlyComputedRef<PushMessage> = computed(
    () => pushMessageRef.value
  );

  const notEditedPushMessage: ReadonlyComputedRef<PushMessage> = computed(
    () => notEditedPushMessageRef.value
  );

  const isClone = computed(() => isCloneRef.value);

  // actions
  /**
   * Use to modify push message fields in store.
   */
  const changeFieldsPushMessage = (
    modifiedPushMessage: Partial<PushMessage>
  ) => {
    pushMessageRef.value = { ...pushMessageRef.value, ...modifiedPushMessage };
  };

  /**
   * Use to set empty push message in store.
   */
  const setEmptyPushMessage = (
    parsedQueryParams?: PushMessageQueryValues,
    useConfigData?: ConfigDataComposable,
    cloneOptions?: CloneOptions
  ) => {
    const emptyPushMessage = buildNewPushMessage(
      parsedQueryParams,
      useConfigData?.().config.value?.defaultTags ?? [],
      cloneOptions
    );

    notEditedPushMessageRef.value = cloneDeep(emptyPushMessage);
    pushMessageRef.value = emptyPushMessage;
    // When caller passes clone options we assume this push message is cloned.
    // TODO(PNS-2448): Split this action into "set empty" & "set empty from cloned".
    isCloneRef.value = cloneOptions !== undefined;
  };

  /**
   * Use to set existing non-modified push message for edit in store.
   */
  const setExistingPushMessage = (pushMessage: PushMessage) => {
    notEditedPushMessageRef.value = cloneDeep(pushMessage);
    pushMessageRef.value = pushMessage;
    isCloneRef.value = false;
  };

  return {
    pushMessage,
    notEditedPushMessage,

    isClone,

    changeFieldsPushMessage,
    setEmptyPushMessage,
    setExistingPushMessage,
  };
});

export type UsePushMessageStore = () => ReturnType<typeof usePushMessageStore>;
