import { Ref, ref } from "vue";

import { FilterEventPayload, ScrollEventPayload } from "@/api/typedefs";

const FIRST_PAGE = 1;
const INITIAL_NEXT_PAGE = 2;
const PER_PAGE_COUNT = 30;

export interface PaginatedData<T> {
  results: T[];
  next: string | null;
}

export type PaginatedFetchMethod<T> = (
  query: string,
  page: number,
  perPage: number
) => Promise<PaginatedData<T>>;

interface PaginatedSelectOptions<T> {
  paginatedFetchMethod: PaginatedFetchMethod<T>;
}

export function usePaginatedSelect<T>({
  paginatedFetchMethod: fetchOptionsPage,
}: PaginatedSelectOptions<T>) {
  const options: Ref<T[]> = ref([]);
  let query = "";
  let nextPage = INITIAL_NEXT_PAGE;
  let allItemsFetched = false;
  let fetchInProgress = false;

  async function fetchOptions({
    value,
    onSuccess,
    onError,
  }: FilterEventPayload): Promise<void> {
    let response;
    try {
      allItemsFetched = false;
      response = await fetchOptionsPage(value, FIRST_PAGE, PER_PAGE_COUNT);
      options.value = response.results;
      query = value;
      nextPage = INITIAL_NEXT_PAGE;
      if (response.next === null) {
        allItemsFetched = true;
      }
      onSuccess();
    } catch (error: unknown) {
      onError();
      throw error;
    }
  }

  async function fetchMoreOptions({
    to,
    ref,
  }: ScrollEventPayload): Promise<void> {
    if (fetchInProgress || allItemsFetched || to !== options.value.length - 1) {
      return;
    }

    fetchInProgress = true;
    const moreOptions = await fetchOptionsPage(query, nextPage, PER_PAGE_COUNT);

    if (moreOptions.next === null) {
      allItemsFetched = true;
    }

    options.value.push(...moreOptions.results);
    nextPage += 1;
    ref.refresh();
    fetchInProgress = false;
  }

  return {
    fetchOptions,
    fetchMoreOptions,
    options,
  };
}

export const TEST_ONLY = {
  FIRST_PAGE,
  INITIAL_NEXT_PAGE,
  PER_PAGE_COUNT,
};
