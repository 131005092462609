import * as Sentry from "@sentry/vue";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { Component, computed, Ref, ref, watch } from "vue";
import { useRouter } from "vue-router";

import { fetchPushMessage } from "@/api/pushnotifications/pushmessage/details";
import { fetchMessageTarget } from "@/api/pushnotifications/pushmessage/target";
import {
  isExistingPushMessage,
  MessageStatus,
  MessageTarget,
  PushMessage,
} from "@/api/pushnotifications/pushmessage/typedefs";
import { ValidationStatus } from "@/api/target/typedefs";
import { AllowedActionButtons } from "@/components/pushmessages/useMessageFormActions";
import { ConfigDataComposable } from "@/composables/useConfigData";
import { UseTestDevices } from "@/composables/useTestDevices";

import {
  UsePushMessageActions,
  UsePushMessageChangesWatcher,
  UsePushMessagePermissions,
  UsePushMessageQueryParams,
  UsePushMessageRouteParams,
  UsePushMessageValidation,
} from "./typedefs";
import { UsePushMessageStore } from "./usePushMessageStore";

interface UsePushMessageOptions {
  usePushMessageRouteParams: UsePushMessageRouteParams;
  usePushMessageActions: UsePushMessageActions;
  usePushMessagePermissions: UsePushMessagePermissions;
  usePushMessageValidation: UsePushMessageValidation;
  usePushMessageQueryParams: UsePushMessageQueryParams;
  usePushMessageChangesWatcher: UsePushMessageChangesWatcher;
  useTestDevices: UseTestDevices;
  useConfigData: ConfigDataComposable;
  usePushMessageStore: UsePushMessageStore;
}

const READ_ONLY_STATUSES = new Set<MessageStatus>([
  "Active",
  "Cancelled",
  "Deleted",
  "Failed",
  "Finished",
  "Paused",
  "Pending",
]);

export function usePushMessage({
  usePushMessageRouteParams,
  usePushMessageActions,
  usePushMessagePermissions,
  usePushMessageValidation,
  useTestDevices,
  usePushMessageQueryParams,
  usePushMessageChangesWatcher,
  useConfigData,
  usePushMessageStore,
}: UsePushMessageOptions) {
  const $q = useQuasar();
  const router = useRouter();

  const {
    mapPushMessageFieldsToQueryParams,
    mapQueryParamsToPushMessageFields,
  } = usePushMessageQueryParams({ router });

  const store = usePushMessageStore();
  const pushMessage = computed(() => store.pushMessage);
  const notEditedPushMessage = computed(() => store.notEditedPushMessage);
  const isClone = computed(() => store.isClone);

  store.setEmptyPushMessage(mapQueryParamsToPushMessageFields(), useConfigData);

  const isLoading = ref(false);

  const validationStatus: Ref<ValidationStatus | null> = ref(null);

  const {
    productLineId,
    pushMessageId,
    cloneFromMessageId,
    cloneFromProductLineId,
    productLine,
  } = usePushMessageRouteParams({});

  const { hasUserTestDevices } = useTestDevices({ productLine });

  const { hasUnsavedChanges } = usePushMessageChangesWatcher({
    pushMessage,
    notEditedPushMessage,
  });

  const { handleValidation, abortValidation } = usePushMessageValidation({
    pushMessage,
    productLineId,
    onValidationStatusChange: (value) => (validationStatus.value = value),
    onTargetChange: (value) => store.changeFieldsPushMessage({ target: value }),
  });

  const {
    hasEditPushMessagePermission,
    hasAddPushMessagePermission,
    productLinesWithAddMessagePermissions,
    hasAddImagePermission,
    hasAddTargetPermission,
    hasDryRunPermissions,
  } = usePushMessagePermissions({
    productLineId,
  });

  const reloadPushMessage = async () => {
    if (!isExistingPushMessage(pushMessage.value)) {
      throw Error("reloadPushMessage called for not existing pushMessage");
    }
    try {
      store.setExistingPushMessage(
        await fetchPushMessage(productLineId.value, pushMessage.value.id)
      );
    } catch (error) {
      $q.notify({
        message:
          "Failed to refresh Push Notification. Please reload page manually.",
        type: "negative",
      });
      Sentry.captureException(error);
    }
  };

  const { isEditingActivePushMessage, ...actionHandlers } =
    usePushMessageActions({
      isLoading,
      productLineId,
      cloneProductLineOptions: productLinesWithAddMessagePermissions,
      pushMessage,
      notEditedPushMessage,
      onValidationStatusChange: (newValue) =>
        (validationStatus.value = newValue),
      onTriggerValidation: async () => handleValidation(),
      reloadPushMessage,
      onPushMessageSubmitted: (pushMessage) =>
        store.setExistingPushMessage(pushMessage),
      onPushMessageReset: () =>
        store.setExistingPushMessage(notEditedPushMessage.value),
    });
  const isReadOnly = computed((): boolean => {
    if (!isExistingPushMessage(pushMessage.value)) {
      return !hasAddPushMessagePermission.value;
    }

    if (!hasEditPushMessagePermission.value) {
      return true;
    }

    return READ_ONLY_STATUSES.has(pushMessage.value.status);
  });

  const canAddMessageOnAnyProductLine = computed(
    (): boolean => productLinesWithAddMessagePermissions.value.length > 0
  );

  const allowedActionButtons = computed((): AllowedActionButtons => {
    return {
      saveNew: hasAddPushMessagePermission.value,
      saveExisting: hasEditPushMessagePermission.value,
      validate: hasEditPushMessagePermission.value,
      clone: canAddMessageOnAnyProductLine.value,
      delete: hasEditPushMessagePermission.value,
      activate: hasEditPushMessagePermission.value,
      cancel: hasEditPushMessagePermission.value,
      edit: hasEditPushMessagePermission.value,
    };
  });

  watch(
    [pushMessageId, cloneFromMessageId],
    async () => {
      isLoading.value = true;

      if (
        cloneFromMessageId.value !== null &&
        cloneFromProductLineId.value !== null
      ) {
        const sourcePushMessage = await fetchPushMessage(
          cloneFromProductLineId.value,
          cloneFromMessageId.value
        );
        store.setEmptyPushMessage(
          mapQueryParamsToPushMessageFields(),
          useConfigData,
          {
            sourcePushMessage: sourcePushMessage,
            isProductLineMatch:
              productLineId.value === cloneFromProductLineId.value,
          }
        );
      } else if (pushMessageId.value === null) {
        store.setEmptyPushMessage(
          mapQueryParamsToPushMessageFields(),
          useConfigData
        );
      } else {
        try {
          store.setExistingPushMessage(
            await fetchPushMessage(productLineId.value, pushMessageId.value)
          );
        } catch (error: unknown) {
          router.push({
            name: "PushMessagesList",
            params: {
              product: pushMessageId.value,
              productLine: productLineId.value,
            },
          });
          $q.notify({
            type: "negative",
            message: `Failed to fetch push message with ID: ${pushMessageId.value}`,
          });
        }
      }
      validationStatus.value = null; // Clear validation status.

      if (
        pushMessage.value.target !== null &&
        isExistingPushMessage(pushMessage.value)
      ) {
        const target = await fetchMessageTarget(
          productLineId.value,
          pushMessage.value.target.id
        );

        validationStatus.value = target.status;

        if (validationStatus.value === ValidationStatus.VALIDATING) {
          await handleValidation(reloadPushMessage);
        }
      }
      isLoading.value = false;
    },
    { immediate: true }
  );

  const onUpdateMessageTarget = (target: MessageTarget | null) => {
    if (validationStatus.value === ValidationStatus.VALIDATING) {
      abortValidation();
      isLoading.value = false;
    }
    validationStatus.value = target?.status ?? null;
    if (validationStatus.value === ValidationStatus.VALIDATING) {
      isLoading.value = true;
      handleValidation();
    }
  };

  const onPushMessageUpdate = (updated: Partial<PushMessage>) => {
    store.changeFieldsPushMessage(updated);

    mapPushMessageFieldsToQueryParams(pushMessage.value);
    if (updated.target !== undefined) {
      onUpdateMessageTarget(updated.target);
    }
  };

  const onFormValidationError = async (component: Component) => {
    $q.notify({ type: "negative", message: "Some fields are incorrect." });

    // TODO(pszyma): This can be functional component.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const element = (component as any).$el as Element | undefined;
    element?.scrollIntoView({ behavior: "smooth" });
  };

  return {
    pushMessage,
    onPushMessageUpdate,
    onFormValidationError,

    // Push message state.
    productLineId,
    pushMessageId,
    cloneFromMessageId,
    cloneFromProductLineId,
    productLine,
    hasUnsavedChanges,
    validationStatus,
    isLoading,
    isEditingActivePushMessage,
    isReadOnly,
    isClone,
    hasAddImagePermission,
    hasAddTargetPermission,
    hasUserTestDevices,

    // Action buttons
    hasDryRunPermissions,
    allowedActionButtons,

    // Actions handlers.
    ...actionHandlers,
  };
}
