
import { computed, defineComponent, PropType } from "vue";

import {
  DeliveryOptions,
  DeliveryType,
} from "@/api/pushnotifications/messagedelivery/typedefs";
import DeliveryOptionsTriggered from "@/components/pushmessages/deliveryoptions/DeliveryOptionsTriggered.vue";

import DeliveryOptionsRecurring from "./DeliveryOptionsRecurring.vue";
import DeliveryOptionsScheduled from "./DeliveryOptionsScheduled.vue";

export default defineComponent({
  props: {
    deliveryType: {
      type: null as unknown as PropType<DeliveryType | null>,
      required: true,
    },
    modelValue: {
      type: null as unknown as PropType<DeliveryOptions | null>,
      validator: (v: unknown) => typeof v === "object" || v === null,
      required: true,
    },
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: {
    "update:modelValue": (v: DeliveryOptions | null) =>
      typeof v === "object" || v === null,
  },
  setup(props) {
    const optionsComponent = computed(() => {
      switch (props.deliveryType) {
        case DeliveryType.SCHEDULED:
          return DeliveryOptionsScheduled;
        case DeliveryType.RECURRING:
          return DeliveryOptionsRecurring;
        case DeliveryType.TRIGGERED:
          return DeliveryOptionsTriggered;
        default:
          return null;
      }
    });

    return {
      optionsComponent,
    };
  },
});
