import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

type ClassValue = string | string[] | Record<string, boolean> | undefined;

const EMPTY_VALUE = "-";


export default /*@__PURE__*/_defineComponent({
  __name: 'ReadonlyField',
  props: {
    label: {},
    value: { default: null },
    labelClass: { default: undefined },
    valueClass: { default: undefined }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: "readonly-field" }, _ctx.$attrs), [
    _createElementVNode("div", {
      class: _normalizeClass(["label", _ctx.labelClass])
    }, _toDisplayString(_ctx.label), 3),
    _createElementVNode("div", {
      class: _normalizeClass(["value", _ctx.valueClass])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        (_ctx.value)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_ctx.value), 1)
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(EMPTY_VALUE))
            ], 64))
      ])
    ], 2)
  ], 16))
}
}

})