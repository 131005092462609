import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-h6" }
const _hoisted_2 = { class: "text-negative text-strike" }
const _hoisted_3 = { class: "text-positive" }
const _hoisted_4 = { key: 0 }

import useDialogPluginComponent from 'quasar/src/composables/use-dialog-plugin-component.js';;

import { ChangedField } from "@/views/pushmessages/typedefs";


export default /*@__PURE__*/_defineComponent({
  __name: 'ActiveMessageSubmitDialog',
  props: {
    changedFields: {}
  },
  emits: useDialogPluginComponent.emits,
  setup(__props: any) {

const props = __props;


const { dialogRef, onDialogOK, onDialogHide, onDialogCancel } =
  useDialogPluginComponent();

return (_ctx: any,_cache: any) => {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    ref_key: "dialogRef",
    ref: dialogRef,
    "data-test": "message.activesubmitdialog",
    onHide: _unref(onDialogHide)
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { class: "dialog-card q-dialog-plugin pushmessage__card--save" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, { class: "text-h5" }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Confirm modifying active Push Notification")
            ])),
            _: 1
          }),
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.changedFields, (changedField) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: changedField.name
                }, [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(changedField.name), 1),
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_2, _toDisplayString(changedField.valueBefore), 1),
                    _createElementVNode("div", _hoisted_3, _toDisplayString(changedField.valueAfter), 1)
                  ])
                ]))
              }), 128)),
              (!props.changedFields.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, "No changes"))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_actions, { align: "right" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                label: "Cancel",
                onClick: _unref(onDialogCancel)
              }, null, 8, ["onClick"]),
              _createVNode(_component_q_btn, {
                "data-test": "message.activesubmitdialog.confirm",
                label: "Confirm",
                color: "primary",
                onClick: _unref(onDialogOK)
              }, null, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onHide"]))
}
}

})