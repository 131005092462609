import {
  ExistingPushMessage,
  NewPushMessageComplete,
  PushMessage,
} from "@/api/pushnotifications/pushmessage/typedefs";

class PushMessageCastError extends Error {}

export function castPushMessageValid(
  pushMessage: PushMessage
): NewPushMessageComplete | ExistingPushMessage {
  if (pushMessage.deliveryType === null) {
    throw new PushMessageCastError("PushMessage invalid, deliveryType is null");
  }
  // Being VERY explicit about deliveryType for the sake of typescript
  return { ...pushMessage, deliveryType: pushMessage.deliveryType };
}
