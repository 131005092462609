import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }

import { useTargetSelectOption } from "@/components/shared/target/select/useTargetSelectOption";


export default /*@__PURE__*/_defineComponent({
  __name: 'TargetSelectOption',
  props: {
    targetName: {},
    affectedUsers: {},
    isDetailedUi: { type: Boolean },
    isRuntime: { type: [Boolean, null] }
  },
  setup(__props: any) {

const props = __props;

const {
  getIsRuntimeIcon,
  getIsRuntimeTooltip,
  getAffectedUsersTooltip,
  getAffectedUsersColor,
  getIsRuntimeColor,
} = useTargetSelectOption();

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_badge = _resolveComponent("q-badge")!

  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.isDetailedUi)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          (_ctx.isDetailedUi)
            ? (_openBlock(), _createBlock(_component_q_badge, {
                key: 0,
                title: _unref(getIsRuntimeTooltip)(props.isRuntime),
                "data-test": "targetselect.option.isruntime",
                outline: "",
                dense: "",
                color: _unref(getIsRuntimeColor)(props.isRuntime),
                class: "q-mx-xs"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, {
                    name: _unref(getIsRuntimeIcon)(props.isRuntime)
                  }, null, 8, ["name"])
                ]),
                _: 1
              }, 8, ["title", "color"]))
            : _createCommentVNode("", true),
          _createVNode(_component_q_badge, {
            "data-test": "targetselect.option.affectedusers",
            title: _unref(getAffectedUsersTooltip)(props.affectedUsers),
            outline: "",
            dense: "",
            color: _unref(getAffectedUsersColor)(props.affectedUsers),
            class: "q-mx-xs"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "person_outline" }),
              _createTextVNode(" " + _toDisplayString(_ctx.affectedUsers), 1)
            ]),
            _: 1
          }, 8, ["title", "color"])
        ]))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_ctx.targetName), 1)
  ]))
}
}

})