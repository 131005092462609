import { QVueGlobals } from "quasar/dist/types";

import { ChangedField } from "@/views/pushmessages/typedefs";

import ActiveMessageSubmitDialog from "./ActiveMessageSubmitDialog.vue";

interface ActiveMessageSubmitOptions {
  onDismiss?: () => void;
  onOk: () => void;
  changedFields: ChangedField[];
  quasar: QVueGlobals;
}

export function useActiveMessageSubmitDialog({
  onOk,
  onDismiss = () => {
    return;
  },
  changedFields,
  quasar,
}: ActiveMessageSubmitOptions) {
  const $q = quasar;
  const openDialog = () => {
    $q.dialog({
      component: ActiveMessageSubmitDialog,
      componentProps: { changedFields },
    })
      .onDismiss(() => onDismiss())
      .onOk(() => onOk());
  };

  return {
    openDialog,
  };
}
