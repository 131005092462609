import useQuasar from 'quasar/src/composables/use-quasar.js';;

import { TargetType } from "@/api/target/typedefs";
import MessageActivateDialog from "@/components/pushmessages/dialogs/MessageActivateDialog.vue";

export interface ActivateDialogPayload {
  productLineId: number;
  targetType: TargetType;
  targetId: number;
  scheduleTime: string | null;
}

export function useMessageActivateDialog() {
  const $q = useQuasar();

  const openDialog = (payload: ActivateDialogPayload) =>
    $q.dialog({
      component: MessageActivateDialog,
      componentProps: {
        productLineId: payload.productLineId,
        targetType: payload.targetType,
        targetId: payload.targetId,
        scheduleTime: payload.scheduleTime,
      },
    });

  return {
    openDialog,
  };
}
