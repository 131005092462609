import * as t from "io-ts";

export const ImageMetadataGuard = t.type({
  id: t.number,
  name: t.string,
  url: t.string,
});
export const ImageResponseGuard = t.type({
  results: t.array(ImageMetadataGuard),
  next: t.union([t.string, t.null]),
});

export type ImageMetadata = t.TypeOf<typeof ImageMetadataGuard>;
export type ImageResponse = t.TypeOf<typeof ImageResponseGuard>;
