import { httpClient, throwIfBadResponse } from "@/lib/http";

export async function activatePushMessage(
  productLine: number,
  id: number
): Promise<void> {
  const response = await httpClient.post(
    `/api/pushnotifications/message/activate/${productLine}/${id}`
  );

  await throwIfBadResponse(response);
}
