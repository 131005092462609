import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "screen" }
const _hoisted_3 = {
  key: 0,
  class: "row justify-center q-my-md"
}

import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { computed, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'VisualPreview',
  props: {
    backgroundUrl: {},
    isExpandable: { type: Boolean }
  },
  setup(__props: any) {

const $q = useQuasar();



const isExpanded = ref(true);

const visualPreviewClasses = computed(() => ({ fixed: $q.screen.gt.sm }));

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["visual-preview", visualPreviewClasses.value])
  }, [
    _createElementVNode("img", {
      src: _ctx.backgroundUrl,
      alt: ""
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", { isExpanded: isExpanded.value })
    ]),
    (_ctx.isExpandable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_q_btn, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (isExpanded.value = !isExpanded.value))
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Toggle view")
            ])),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})