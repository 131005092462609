import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-gutter-y-sm" }

import {
  DeliveryOptionsTriggered,
  DeliveryTriggeredTrigger,
  DeliveryTriggeredTriggerGuard,
} from "@/api/pushnotifications/messagedelivery/typedefs";
import DateTimeInput from "@/components/shared/DateTimeInput.vue";
import NumberInput from "@/components/shared/NumberInput.vue";
import { isEndDateAfterStartDate } from "@/lib/dateTime/dateTime";
import { bindPropToRef } from "@/lib/propToRef";


export default /*@__PURE__*/_defineComponent({
  __name: 'DeliveryOptionsTriggered',
  props: {
    modelValue: {},
    disable: { type: Boolean, default: false }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

class DeliveryOptionsTriggeredError extends Error {}

const props = __props;

const deliveryOptions = bindPropToRef<DeliveryOptionsTriggered>(
  () => props.modelValue,
  updateModelValue
);

const emit = __emit;

const triggerSelectOptions: DeliveryTriggeredTrigger[] = Object.keys(
  DeliveryTriggeredTriggerGuard.keys
) as DeliveryTriggeredTrigger[];

function updateModelValue(newData: Partial<DeliveryOptionsTriggered>) {
  if (!props.modelValue) {
    throw new DeliveryOptionsTriggeredError(
      "Illegal call to updateModelValue. DeliveryOptions not initialized."
    );
  }
  emit("update:modelValue", {
    ...props.modelValue,
    ...newData,
  });
}

return (_ctx: any,_cache: any) => {
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_toggle = _resolveComponent("q-toggle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_select, {
      modelValue: _unref(deliveryOptions).trigger,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(deliveryOptions).trigger) = $event)),
      "data-test": "deliveryoptions.triggered.trigger",
      disable: _ctx.disable,
      label: "Trigger",
      hint: "Trigger type that executes the push",
      options: _unref(triggerSelectOptions),
      filled: "",
      clearable: ""
    }, null, 8, ["modelValue", "disable", "options"]),
    _createVNode(NumberInput, {
      modelValue: _unref(deliveryOptions).delaySeconds,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(deliveryOptions).delaySeconds) = $event)),
      modelModifiers: { number: true },
      "data-test": "deliveryoptions.triggered.delayseconds",
      disable: _ctx.disable,
      type: "number",
      label: "Delay seconds",
      hint: "Delay in seconds from trigger to display",
      filled: "",
      clearable: "",
      min: "1",
      max: "5000000"
    }, null, 8, ["modelValue", "disable"]),
    _createVNode(_component_q_toggle, {
      modelValue: _unref(deliveryOptions).allowShowIfAppForeground,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(deliveryOptions).allowShowIfAppForeground) = $event)),
      "data-test": "deliveryoptions.triggered.allowshowifappforeground",
      disable: _ctx.disable,
      label: "Show also when the app is active in foreground"
    }, null, 8, ["modelValue", "disable"]),
    _createVNode(DateTimeInput, {
      modelValue: _unref(deliveryOptions).startDateTime,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(deliveryOptions).startDateTime) = $event)),
      "data-test": "deliveryoptions.triggered.startdatetime",
      label: "Start date time",
      hint: "When to start executing push",
      disable: _ctx.disable
    }, null, 8, ["modelValue", "disable"]),
    _createVNode(DateTimeInput, {
      modelValue: _unref(deliveryOptions).endDateTime,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(deliveryOptions).endDateTime) = $event)),
      "data-test": "deliveryoptions.triggered.enddatetime",
      label: "End date time",
      hint: "When to stop executing push",
      disable: _ctx.disable,
      "extra-rules": [
        (val) =>
          (val &&
            _unref(isEndDateAfterStartDate)(_unref(deliveryOptions).startDateTime, val)) ||
          'End date time is required and must be after start date time',
      ]
    }, null, 8, ["modelValue", "disable", "extra-rules"])
  ]))
}
}

})