import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.optionsComponent)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.optionsComponent), {
        key: 0,
        "model-value": _ctx.modelValue,
        disable: _ctx.disable,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
      }, null, 8, ["model-value", "disable"]))
    : _createCommentVNode("", true)
}