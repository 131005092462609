
import QForm from 'quasar/src/components/form/QForm.js';;
import { defineComponent, PropType, Ref, ref, toRef } from "vue";

import TimeInput from "@/components/shared/TimeInput.vue";

import { useDateTimeInput } from "./useDateTimeInput";
import { useInputProps } from "./useInputProps";

export default defineComponent({
  components: {
    TimeInput,
  },
  props: {
    ...useInputProps(),
    modelValue: {
      type: null as unknown as PropType<string | null>,
      required: true,
      validator: (v: unknown) => typeof v === "string" || v === null,
    },
  },
  emits: {
    "update:modelValue": (v: string | null) =>
      typeof v === "string" || v === null,
  },
  setup(props, { emit }) {
    const formRef: Ref<QForm | null> = ref(null);

    return {
      ...useDateTimeInput({
        modelValue: toRef(props, "modelValue"),
        onDateTimeChange: (value) => emit("update:modelValue", value),
        extraRules: toRef(props, "extraRules"),
      }),
      formRef,
    };
  },
});
