import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { computed, Ref, ref, watch } from "vue";
import { Router, useRoute, useRouter } from "vue-router";

import { getProductLine, PRODUCT_LINES, ProductLine } from "@/lib/productLine";
import { QuasarNotify } from "@/lib/quasar";

import { UsePushMessageRouteParams } from "./typedefs";

function getProductLineOrRedirectToMessagesList(
  routeValue: unknown,
  router: Router,
  $q: QuasarNotify
): ProductLine {
  try {
    return getProductLine(Number(routeValue));
  } catch (error: unknown) {
    const fallbackProductLine = PRODUCT_LINES[0];
    router.push({
      name: "PushMessagesList",
      params: {
        product: fallbackProductLine.productId,
        productLine: fallbackProductLine.id,
      },
    });
    $q.notify({
      type: "negative",
      message: `Invalid product line ID: ${routeValue}`,
    });
    return fallbackProductLine;
  }
}

export const usePushMessageRouteParams: UsePushMessageRouteParams = () => {
  const route = useRoute();
  const router = useRouter();
  const $q = useQuasar();

  const productLine: Ref<ProductLine> = ref(
    getProductLineOrRedirectToMessagesList(
      route.params["productLineProp"],
      router,
      $q
    )
  );

  watch(
    () => router.currentRoute.value,
    () => {
      // Product line can be invalid, and in that case we want to redirect
      // to messages list with error. Computed properties require no side
      // effects. That is why watcher is used.
      const productLineId = Number(route.params["productLineProp"]);
      if (!Number.isInteger(productLineId)) return;
      productLine.value = getProductLineOrRedirectToMessagesList(
        route.params["productLineProp"],
        router,
        $q
      );
    }
  );

  const pushMessageId = computed(() => {
    const rawValue = route.params["pushMessageId"];
    if (!rawValue) return null;
    const value = Number(rawValue);
    if (!Number.isInteger(value)) return null;
    return value;
  });

  const cloneFromMessageId = computed(() => {
    const queryParam = route.query["cloneFromMessageId"];
    if (!queryParam) return null;

    const id = Number(queryParam);
    if (!Number.isInteger(id)) return null;

    return id;
  });

  const cloneFromProductLineId = computed(() => {
    const queryParam = route.query["cloneFromProductLineId"];
    if (!queryParam) return null;

    const id = Number(queryParam);
    if (!Number.isInteger(id)) return null;

    return id;
  });

  return {
    productLineId: computed(() => productLine.value?.id ?? null),
    pushMessageId,
    cloneFromMessageId,
    cloneFromProductLineId,
    productLine: computed(() => productLine.value), // Wrap into computed to it is read-only outside.
  };
};
