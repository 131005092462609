import { fetchSegmentValues } from "@/api/segments/values";
import { FilterEventPayload, ScrollEventPayload } from "@/api/typedefs";
import { usePaginatedSelect } from "@/composables/usePaginatedSelect";
import { ProductLine } from "@/lib/productLine";
import { Composable, ReadonlyRef } from "@/lib/typing";

export interface UseSegmentSelectOptions {
  segmentName: string;
  productLine: ProductLine;
}

interface UseSegmentSelectReturn {
  fetchSegmentOptions: (payload: FilterEventPayload) => Promise<void>;
  fetchMoreSegmentOptions: (payload: ScrollEventPayload) => Promise<void>;
  segmentOptions: ReadonlyRef<string[]>;
}

export const useSegmentSelect: Composable<
  UseSegmentSelectOptions,
  UseSegmentSelectReturn
> = ({ segmentName, productLine }) => {
  const {
    fetchOptions: fetchSegmentOptions,
    fetchMoreOptions: fetchMoreSegmentOptions,
    options: segmentOptions,
  } = usePaginatedSelect({
    paginatedFetchMethod: async (query, page, perPage) => {
      const segments = await fetchSegmentValues(
        segmentName,
        productLine.productId,
        query,
        page,
        perPage
      );
      return segments.map((value) => value.name);
    },
  });
  return {
    fetchSegmentOptions,
    fetchMoreSegmentOptions,
    segmentOptions,
  };
};
