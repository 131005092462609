import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-center items-center q-py-lg"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "q-py-lg text-center" }
const _hoisted_4 = { class: "q-my-md q-col-gutter-y-md text-center" }
const _hoisted_5 = { class: "row q-col-gutter-y-md q-col-gutter-x-md" }
const _hoisted_6 = { class: "col-auto col-grow" }
const _hoisted_7 = { class: "col-auto col-grow" }
const _hoisted_8 = { class: "col-auto col-grow" }
const _hoisted_9 = { class: "col-auto col-grow" }
const _hoisted_10 = { class: "col-auto col-grow" }
const _hoisted_11 = { class: "col-auto col-grow" }
const _hoisted_12 = { class: "row q-pa-none flex justify-center" }

import QSpinner from 'quasar/src/components/spinner/QSpinner.js';;
import { computed, onMounted, Ref, ref, toRefs, watch } from "vue";

import { TargetType, TargetUnified } from "@/api/target/typedefs";
import ReadonlyField from "@/components/shared/ReadonlyField.vue";
import TargetingPicker from "@/components/targetingpicker/TargetingPicker.vue";
import { isNodeObject } from "@/components/targetingpicker/utils/guards";
import { useTargetingData } from "@/composables/useTargetingData";
import { formatPretty } from "@/lib/format";


export default /*@__PURE__*/_defineComponent({
  __name: 'TargetDetail',
  props: {
    targetChoice: {},
    productId: {},
    productLineId: {},
    targetType: {}
  },
  setup(__props: any) {

const props = __props;

const { productLineId, targetType, targetChoice } = toRefs(props);

const error: Ref<string | null> = ref(null);

const targetId = computed(() => targetChoice.value?.id);

const { isLoading, targetValue, fetchData } = useTargetingData({
  productLineId: productLineId,
  targetType: targetType,
  targetId: targetId,
  onError: () => {
    error.value = "Could not fetch targeting details.";
  },
  onSuccess() {
    error.value = null;
  },
});

const affectedUsers = computed(() => {
  const rawText =
    targetValue.value?.affectedUsers?.[
      targetValue.value?.affectedUsers.length - 1
    ]?.[0];

  if (rawText === undefined) {
    return "N/A";
  }

  const rawNumber = parseInt(rawText, 10);

  return !isNaN(rawNumber) ? formatPretty(rawNumber) : "N/A";
});

onMounted(fetchData);
watch([targetChoice], () => fetchData(), {
  deep: true,
});

return (_ctx: any,_cache: any) => {
  const _component_q_card_section = _resolveComponent("q-card-section")!

  return (_unref(isLoading) && Number(_unref(targetValue).targetId) !== _unref(targetChoice)?.id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_unref(QSpinner), {
          "data-test": "ia.loading",
          size: "xl",
          color: "primary"
        })
      ]))
    : (error.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(error.value), 1)
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(ReadonlyField, { label: "Timezone" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(targetValue).timezoneOffset), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(ReadonlyField, {
                      label: "Runtime",
                      "value-class": {
                'text-green': !!_unref(targetValue).isRuntime,
                'text-primary': !_unref(targetValue).isRuntime,
              }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(targetValue).isRuntime ? "Yes" : "No"), 1)
                      ]),
                      _: 1
                    }, 8, ["value-class"])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(ReadonlyField, {
                      label: "Premium users",
                      "value-class": {
                'text-warning': !_unref(targetValue).includePremiumUsers,
              }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(targetValue).includePremiumUsers ? "Yes" : "No"), 1)
                      ]),
                      _: 1
                    }, 8, ["value-class"])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(ReadonlyField, {
                      label: "Estimated users",
                      "value-class": { 'text-orange': Number(affectedUsers.value) === 0 }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(affectedUsers.value), 1)
                      ]),
                      _: 1
                    }, 8, ["value-class"])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(ReadonlyField, { label: "Created by" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(targetValue).createdBy?.username || "-"), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(ReadonlyField, { label: "Last modified" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(targetValue).modifiedAt || "-"), 1)
                      ]),
                      _: 1
                    })
                  ])
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_12, [
                (_unref(isNodeObject)(_unref(targetValue).segments))
                  ? (_openBlock(), _createBlock(TargetingPicker, {
                      key: 0,
                      modelValue: _unref(targetValue).segments,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(targetValue).segments) = $event)),
                      "product-line-id": props.productLineId,
                      "product-id": props.productId,
                      type: props.targetType,
                      dictionary: _unref(targetValue).dictionary,
                      "read-only": true,
                      dense: ""
                    }, null, 8, ["modelValue", "product-line-id", "product-id", "type", "dictionary"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ], 64))
}
}

})