import { QVueGlobals } from "quasar/dist/types";
import { computed, ComputedRef } from "vue";

import {
  DeliveryOptions,
  DeliveryOptionsGuard,
  DeliveryOptionsImmediate,
  DeliveryOptionsRecurring,
  DeliveryOptionsScheduled,
  DeliveryOptionsTriggered,
  DeliveryType,
  DeliveryTypeOption,
} from "@/api/pushnotifications/messagedelivery/typedefs";
import { fetchMessageTargets } from "@/api/pushnotifications/pushmessage/target";
import {
  ExistingPushMessage,
  isExistingPushMessage,
  PushMessage,
} from "@/api/pushnotifications/pushmessage/typedefs";
import { FetchMultipleTargetFunction } from "@/components/shared/target/typedefs";
import {
  cast,
  Composable,
  ReadonlyComputedRef,
  TypeCastError,
} from "@/lib/typing";

const CampaignAMGUrlPrefix = "https://amg.opera.com/campaign";
class BuildDeliveryOptionsError extends Error {}

export const deliveryTypeOptions: DeliveryTypeOption[] = [
  {
    label: "Immediate",
    value: DeliveryType.IMMEDIATE,
  },
  {
    label: "Recurring",
    value: DeliveryType.RECURRING,
  },
  {
    label: "Scheduled",
    value: DeliveryType.SCHEDULED,
  },
  {
    label: "Triggered",
    value: DeliveryType.TRIGGERED,
  },
];

function buildDeliveryOptionsImmediate(): DeliveryOptionsImmediate {
  return {};
}
function buildDeliveryOptionsRecurring(): DeliveryOptionsRecurring {
  return {
    time: "00:00",
    interval: null,
    dateRange: {
      from: "",
      to: "",
    },
  };
}
function buildDeliveryOptionsScheduled(): DeliveryOptionsScheduled {
  return {
    dateTime: "",
  };
}
function buildDeliveryOptionsTriggered(): DeliveryOptionsTriggered {
  return {
    delaySeconds: 0,
    allowShowIfAppForeground: false,
    trigger: "start",
    startDateTime: null,
    endDateTime: "",
  };
}

export interface UseMessageFormOptions {
  quasar: QVueGlobals;
  pushMessage: ReadonlyComputedRef<PushMessage>;
  updateMessageHandler: (pushMessage: Partial<PushMessage>) => void;
  fetchTargetsHandler: typeof fetchMessageTargets;
  isEditingActivePushMessage: ReadonlyComputedRef<boolean>;
  isLoading: ReadonlyComputedRef<boolean>;
  isReadonly: ReadonlyComputedRef<boolean>;
}

export interface UseMessageForm {
  updatePushMessageDeliveryOptions: (deliveryOptions: unknown) => void;
  updateDeliveryOptionsType: (deliveryType: DeliveryType) => void;
  fetchTargets: FetchMultipleTargetFunction;
  selectedDeliveryType: ReadonlyComputedRef<DeliveryTypeOption | null>;
  isDisabled: ReadonlyComputedRef<boolean>;
  isRedirectorStatsUrlVisible: ReadonlyComputedRef<boolean>;
  isEditingActiveScheduledPushMessage: ReadonlyComputedRef<boolean>;
  isTargetEditingEnabled: ReadonlyComputedRef<boolean>;
  campaignAMGUrl: ReadonlyComputedRef<string | null>;
  targetErrorMessage: ReadonlyComputedRef<string | null>;
  formatMessageId: ComputedRef<string>;
  isConversionReportVisible: ReadonlyComputedRef<boolean>;
}
export const useMessageForm: Composable<
  UseMessageFormOptions,
  UseMessageForm
> = (options) => {
  const updatePushMessageDeliveryOptions = (deliveryOptions: unknown): void => {
    try {
      options.updateMessageHandler({
        deliveryOptions: cast(DeliveryOptionsGuard, deliveryOptions),
      });
    } catch (e: unknown) {
      if (!(e instanceof TypeCastError)) {
        throw e;
      }
      options.quasar.notify({
        type: "negative",
        message: "Invalid delivery options",
      });
    }
  };

  const updateDeliveryOptionsType = (deliveryType: DeliveryType): void => {
    let deliveryOptions: DeliveryOptions;
    switch (deliveryType) {
      case DeliveryType.IMMEDIATE:
        deliveryOptions = buildDeliveryOptionsImmediate();
        break;
      case DeliveryType.RECURRING:
        deliveryOptions = buildDeliveryOptionsRecurring();
        break;
      case DeliveryType.SCHEDULED:
        deliveryOptions = buildDeliveryOptionsScheduled();
        break;
      case DeliveryType.TRIGGERED:
        deliveryOptions = buildDeliveryOptionsTriggered();
        break;
      default:
        throw new BuildDeliveryOptionsError(
          `Invalid delivery type: ${deliveryType}`
        );
    }
    options.updateMessageHandler({
      deliveryType,
      deliveryOptions: deliveryOptions,
      target:
        !options.pushMessage.value.target?.isRuntime &&
        deliveryType === DeliveryType.TRIGGERED
          ? null
          : options.pushMessage.value.target,
    });
  };

  const selectedDeliveryType = computed(
    () =>
      deliveryTypeOptions.find(
        (o) => o.value === options.pushMessage.value.deliveryType
      ) ?? null
  );

  const isDisabled = computed(
    () => options.isLoading.value || options.isReadonly.value
  );

  const isRedirectorStatsUrlVisible = computed(
    () =>
      isExistingPushMessage(options.pushMessage.value) &&
      options.pushMessage.value.redirectorStatsUrl !== null
  );

  const isEditingActiveScheduledPushMessage = computed(
    () =>
      options.isEditingActivePushMessage.value &&
      options.pushMessage.value.deliveryType === DeliveryType.SCHEDULED
  );

  const isTargetEditingEnabled = computed(() =>
    Boolean(options.pushMessage.value.deliveryType)
  );

  const fetchTargets: FetchMultipleTargetFunction = (
    ...fetchHandlerOptions
  ) => {
    return options.fetchTargetsHandler(
      ...fetchHandlerOptions,
      Boolean(options.pushMessage.value.deliveryType === DeliveryType.TRIGGERED)
    );
  };
  const campaignAMGUrl = computed(() => {
    if (
      isExistingPushMessage(options.pushMessage.value) &&
      options.pushMessage.value.campaignId !== null
    ) {
      return `${CampaignAMGUrlPrefix}/${options.pushMessage.value.campaignId}/`;
    }
    return null;
  });

  const targetErrorMessage = computed<string | null>(() => {
    const selectedTarget = options.pushMessage.value.target;
    if (!selectedTarget) {
      return null;
    }
    if (
      selectedDeliveryType.value?.value === DeliveryType.TRIGGERED &&
      selectedTarget?.isRuntime === false
    ) {
      return "You can't use a non-runtime target with triggered delivery type";
    }
    return null;
  });

  const formatMessageId = computed<string>(() => {
    if (isExistingPushMessage(options.pushMessage.value)) {
      const msgID = (options.pushMessage.value as ExistingPushMessage).id;
      return `"pn_` + msgID + `"`;
    }
    return "";
  });

  const isConversionReportVisible = computed<boolean>(() => {
    if (isExistingPushMessage(options.pushMessage.value)) {
      switch ((options.pushMessage.value as ExistingPushMessage).status) {
        case "Active":
        case "Finished":
        case "Failed":
          return true;
      }
    }
    return false;
  });

  return {
    updatePushMessageDeliveryOptions,
    updateDeliveryOptionsType,
    selectedDeliveryType,
    isDisabled,
    isRedirectorStatsUrlVisible,
    isEditingActiveScheduledPushMessage,
    isTargetEditingEnabled,
    fetchTargets,
    campaignAMGUrl,
    targetErrorMessage,
    formatMessageId,
    isConversionReportVisible,
  };
};
