/**
 * Wrapper over Promise that allows to resolve/reject it
 * outside of Promise handler.
 */
export class Deferred<T, E extends Error = Error> {
  private resolveFn: ((arg: T) => void) | null = null;
  private rejectFn: ((arg: E) => void) | null = null;
  promise: Promise<T>;

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.resolveFn = resolve;
      this.rejectFn = reject;
    });
  }

  resolve(value: T): void {
    this?.resolveFn?.(value);
  }

  reject(error: E): void {
    this?.rejectFn?.(error);
  }

  value(): Promise<T> {
    return this.promise;
  }
}
