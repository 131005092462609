import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, createSlots as _createSlots, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import QSelect from 'quasar/src/components/select/QSelect.js';import useFormChild from 'quasar/src/composables/use-form-child.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { onMounted, Ref, ref } from "vue";

import { ImageMetadata } from "@/api/image/typedefs";
import { useImageSelect } from "@/components/shared/imageselect/useImageSelect";
import { OnFilter, OnFilterAbortFn, OnFilterDoneFn } from "@/lib/quasar";
import { ValidationRule } from "@/lib/rules/typedefs.js";


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageSelect',
  props: {
    modelValue: {},
    hint: {},
    label: {},
    rules: {},
    disable: { type: Boolean },
    isAddImageButtonVisible: { type: Boolean },
    imageConstraintNames: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const $q = useQuasar();

const imageOptionLabel = (image: ImageMetadata) => {
  return image.name;
};

const props = __props;
const emit = __emit;
const fileUploadRef: Ref<HTMLInputElement | null> = ref(null);
const selectRef: Ref<QSelect | null> = ref(null);

const {
  startFileUploadRefListener,
  openFilePicker,
  fetchImageOptions,
  fetchMoreImageOptions,
  imageOptions,
} = useImageSelect({
  fileUploadRef,
  fileSelectedCallback: (file) => emit("update:modelValue", file),
  imageConstraintNames: props.imageConstraintNames,
  onUploadSuccessCallback: (message) =>
    $q.notify({ type: "positive", message }),
  onUploadErrorCallback: (message) => $q.notify({ type: "negative", message }),
});

onMounted(() => {
  startFileUploadRefListener();
});

const onScroll = async ({ to, ref }: { to: number; ref: QSelect }) => {
  await fetchMoreImageOptions({ to, ref });
};

const onFilter: OnFilter = async (
  inputValue: string,
  doneFn: OnFilterDoneFn,
  abortFn: OnFilterAbortFn
) => {
  await fetchImageOptions({
    value: inputValue,
    onSuccess: () => doneFn(() => undefined),
    onError: abortFn,
  });
};

useFormChild({
  validate: () => selectRef.value?.validate?.() ?? false,
  resetValidation: () => selectRef.value?.resetValidation(),
  requiresQForm: false,
});

return (_ctx: any,_cache: any) => {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item = _resolveComponent("q-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(QSelect), _mergeProps(_ctx.$attrs, {
      ref_key: "selectRef",
      ref: selectRef,
      "model-value": props.modelValue,
      filled: "",
      clearable: "",
      "use-input": "",
      label: props.label ?? undefined,
      hint: props.hint ?? undefined,
      options: _unref(imageOptions),
      disable: props.disable,
      rules: props.rules,
      "option-label": imageOptionLabel,
      onFilter: onFilter,
      onVirtualScroll: onScroll,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
    }), _createSlots({
      "selected-item": _withCtx((scope) => [
        _createVNode(_component_q_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_item_section, { avatar: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_img, {
                  src: scope.opt.url
                }, null, 8, ["src"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_item_section, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_label, { "data-test": "imageselect.selecteditem.label" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(scope.opt.name), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024)
      ]),
      option: _withCtx((scope) => [
        _createVNode(_component_q_item, _normalizeProps(_guardReactiveProps(scope.itemProps)), {
          default: _withCtx(() => [
            _createVNode(_component_q_item_section, { avatar: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_img, {
                  src: scope.opt.url,
                  "data-test": "imageselect.option.img"
                }, null, 8, ["src"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_q_item_section, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_label, { "data-test": "imageselect.option.label" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(scope.opt.name), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1040)
      ]),
      _: 2
    }, [
      (props.isAddImageButtonVisible)
        ? {
            name: "append",
            fn: _withCtx(() => [
              _createVNode(_component_q_btn, {
                round: "",
                dense: "",
                flat: "",
                icon: "add",
                "data-test": "imageselect.button.addfile",
                onClick: _unref(openFilePicker)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_tooltip, null, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("Click here to add a new image")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            key: "0"
          }
        : undefined
    ]), 1040, ["model-value", "label", "hint", "options", "disable", "rules"]),
    _createElementVNode("input", {
      ref_key: "fileUploadRef",
      ref: fileUploadRef,
      type: "file",
      accept: "image/png, image/jpeg"
    }, null, 512)
  ], 64))
}
}

})