import { httpClient, throwIfBadResponse } from "@/lib/http";

/**
 * This method requests validation of push message.
 * It does not return validation result.
 */
export async function requestPushMessageValidation(
  id: number,
  productLineId: number
): Promise<void> {
  const response = await httpClient.get(
    `/api/pushnotifications/message/validate/${productLineId}/${id}`
  );

  await throwIfBadResponse(response);
}
