import useQuasar from 'quasar/src/composables/use-quasar.js';;

import { fetchMessageTarget } from "@/api/pushnotifications/pushmessage/target";
import { ValidationStatus } from "@/api/target/typedefs";
import { Deferred } from "@/lib/deferred";
import { domUtils } from "@/lib/domUtils";
import { assertError } from "@/lib/utils";
import { UsePushMessageValidation } from "@/views/pushmessages/typedefs";

class ValidationAbortedError extends Error {}

const INTERVAL_TIMEOUT = 3000; // ms.

interface OngoingValidation {
  deferred: Deferred<ValidationStatus | null>;
  intervalId: number;
}

export const usePushMessageValidation: UsePushMessageValidation = ({
  pushMessage,
  productLineId,
  onTargetChange,
  onValidationStatusChange,
}) => {
  let ongoingValidation: OngoingValidation | null = null;
  const $q = useQuasar();

  function clearValidationInterval() {
    if (ongoingValidation !== null) {
      domUtils.getWindow().clearInterval(ongoingValidation.intervalId);
    }
    ongoingValidation = null;
  }

  async function updateValidationStatus() {
    if (pushMessage.value.target === null) {
      throw new Error(
        "Started validation interval for message without target."
      );
    }

    if (ongoingValidation === null) {
      return;
    }

    try {
      const target = await fetchMessageTarget(
        productLineId.value,
        pushMessage.value.target.id
      );

      if (
        !(
          target.status === ValidationStatus.VALIDATING ||
          target.status === null
        )
      ) {
        ongoingValidation.deferred.resolve(target.status);
        clearValidationInterval();
      }
    } catch (error) {
      assertError(error);
      ongoingValidation.deferred.reject(error);
      clearValidationInterval();
    }
  }

  function abortValidation() {
    if (ongoingValidation !== null) {
      ongoingValidation.deferred.reject(
        new ValidationAbortedError("Validation aborted.")
      );
      clearValidationInterval();
    }
  }

  function triggerValidationInterval(): Promise<ValidationStatus | null> {
    if (ongoingValidation !== null) {
      abortValidation();
    }

    const intervalId = domUtils
      .getWindow()
      .setInterval(updateValidationStatus, INTERVAL_TIMEOUT);

    const deferred = new Deferred<ValidationStatus | null>();
    ongoingValidation = {
      deferred,
      intervalId,
    };

    return ongoingValidation.deferred.value();
  }

  const onValidationError = async () => {
    if (pushMessage.value.target === null) {
      throw new Error(
        "Trying to fetch validation status for message without target."
      );
    }

    try {
      onTargetChange(
        await fetchMessageTarget(
          productLineId.value,
          pushMessage.value.target.id
        )
      );
      onValidationStatusChange(pushMessage.value.target.status);
      $q.notify({
        type: "negative",
        message: "Validation failed.",
      });
    } catch {
      $q.notify({
        type: "negative",
        message: "Failed to fetch target status.",
      });
    }
  };

  const handleValidation = async (onSuccess?: () => Promise<void>) => {
    try {
      onValidationStatusChange(await triggerValidationInterval());
      $q.notify({ message: "Message validated." });
    } catch (error) {
      if (!(error instanceof ValidationAbortedError)) {
        await onValidationError();
      }
    }

    await onSuccess?.();
  };

  return {
    triggerValidationInterval,
    abortValidation,
    handleValidation,
  };
};
