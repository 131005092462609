import { castBoolean, castNumber, castString } from "@/lib/casts";
import { HTTPClient, throwIfBadResponse } from "@/lib/http";
import { cast } from "@/lib/typing";

import {
  TargetUnified,
  ValidationStatus,
  ValidationStatusGuard,
} from "./typedefs";

function convertValidationStatus(data: unknown): ValidationStatus | null {
  try {
    return cast(ValidationStatusGuard, data);
  } catch (error) {
    return null;
  }
}

function convertAffectedUsers(data: unknown): number | null {
  if (!Array.isArray(data)) return null;
  if (data.length < 1) return null;

  const lastEntry = data[0];
  const lastEntryAffectedCount = Number(lastEntry[0].replace(/\s/g, ""));
  if (!Number.isInteger(lastEntryAffectedCount)) return null;

  return lastEntryAffectedCount;
}

function convertIsRuntime(data: unknown): boolean {
  return castBoolean(data);
}

function convertTarget(data: unknown): TargetUnified {
  const targetData = data as {
    id?: unknown;
    text?: unknown;
    name?: unknown;
    status?: unknown;
    affected_users?: unknown;
    is_runtime?: unknown;
  };

  return {
    id: castNumber(targetData["id"]),
    name: castString(targetData?.["name"] ?? targetData?.["text"]),
    status: convertValidationStatus(targetData?.["status"]),
    affectedUsers: convertAffectedUsers(targetData?.["affected_users"]),
    isRuntime: convertIsRuntime(targetData?.["is_runtime"]),
  };
}

function convertTargets(data: unknown): TargetUnified[] {
  const targetsData = data as {
    matches?: unknown;
  };
  if (!Array.isArray(targetsData["matches"])) {
    throw new Error(`Failed to convert targets: ${JSON.stringify(data)}`);
  }
  return targetsData["matches"].map((item) => convertTarget(item));
}

export type TargetApiUrlBuilderSingleTarget = (
  productLineId: number,
  targetId: number
) => string;

export type TargetApiUrlBuilderMultipleTargets = (
  productLineId: number
) => string;

export class TargetUnifiedApi {
  /*
    TODO(PNS-2880): TargetUnifiedApi is introduced to handle legacy API endpoints
    not returning the full Target data.
   */
  constructor(
    private readonly httpClient: HTTPClient,
    private readonly urlBuilderSingleTarget: TargetApiUrlBuilderSingleTarget,
    private readonly urlBuilderMultipleTargets: TargetApiUrlBuilderMultipleTargets
  ) {}

  async fetchSingleTarget(
    productLine: number,
    targetId: number
  ): Promise<TargetUnified> {
    const url = this.urlBuilderSingleTarget(productLine, targetId);
    const response = await this.httpClient.get(url);

    await throwIfBadResponse(response);

    const data = await response.json();

    return this.convertTarget(data);
  }

  async fetchMultipleTargets(
    productLine: number,
    query: string,
    page: number,
    perPage: number,
    fetchRuntimeOnly: boolean
  ): Promise<TargetUnified[]> {
    const url = this.urlBuilderMultipleTargets(productLine);
    const baseQueryParams = {
      q: query,
      per_page: String(perPage),
      page: String(page),
    };
    const queryParams = fetchRuntimeOnly
      ? { ...baseQueryParams, is_runtime: "true" }
      : baseQueryParams;
    const response = await this.httpClient.get(url, queryParams);

    await throwIfBadResponse(response);

    const data = await response.json();

    return convertTargets(data);
  }

  convertTarget(rawData: unknown): TargetUnified {
    return convertTarget(rawData);
  }
}
