import { computed, ref, Ref } from "vue";

import { ProductLine } from "@/lib/productLine";

const PRODUCT_TITLE_TO_HEADING = new Map([
  ["Mini Final", "Opera Mini"],
  ["Mini Beta", "Opera Mini beta"],
  ["Mini Development", "Opera Mini"],
  ["OfA Final", "Opera"],
  ["OfA Beta", "Opera beta"],
  ["OfA Development", "Opera"],
]);

const DEFAULT_HEADING = "Opera";

interface MessageFormVisualPreviewOptions {
  productLine: Ref<ProductLine | null>;
}

export function useMessageFormVisualPreview({
  productLine,
}: MessageFormVisualPreviewOptions) {
  const heading = computed(() => {
    if (productLine.value === null) return DEFAULT_HEADING;
    const { name, productName } = productLine.value;
    const title = `${productName} ${name}`;
    return PRODUCT_TITLE_TO_HEADING.get(title) ?? DEFAULT_HEADING;
  });

  const iconUrl = computed(() => {
    if (productLine.value === null) return null;
    return productLine.value.iconUrl;
  });

  const isExpanded = ref(true);

  return {
    heading,
    iconUrl,
    isExpanded,
  };
}
