import { castString } from "@/lib/casts";

import { PreviewResult } from "./typedefs";

export function convertPreviewResult(rawData: unknown): PreviewResult {
  const resultData = rawData as {
    message?: unknown;
    status?: unknown;
  };

  return {
    message: castString(resultData.message),
    status: resultData.status === "OK" ? "OK" : "ERROR",
  };
}
