import camelcaseKeys from "camelcase-keys";

import { cast } from "@/lib/typing";

import { ImageMetadata, ImageMetadataGuard } from "./typedefs";

export function convertImage(data: unknown): ImageMetadata {
  const imageData = data as {
    id?: unknown;
    name?: unknown;
    url?: unknown;
  };

  if (typeof imageData !== "object") {
    throw new Error(`Invalid Image data type to convert - ${data}`);
  }

  // TODO(PNS-1738): Drop when BE will start sending camelCase.
  const camelCasedData = camelcaseKeys({
    id: imageData["id"],
    name: imageData["name"],
    url: imageData["url"],
  });

  return cast(ImageMetadataGuard, camelCasedData);
}
