import { TargetUnifiedApi } from "@/api/target/targetUnifiedApi";
import { httpClient } from "@/lib/http";

const targetApi = new TargetUnifiedApi(
  httpClient,
  (productLine: number, targetId: number) =>
    `/api/pushnotifications/product-lines/${productLine}/message-targets/${targetId}/`,
  (productLine: number) => `/pushnotifications/target-list/${productLine}`
);

export const fetchMessageTarget: typeof targetApi.fetchSingleTarget = (
  ...args
) => targetApi.fetchSingleTarget(...args);

export const fetchMessageTargets: typeof targetApi.fetchMultipleTargets = (
  ...args
) => targetApi.fetchMultipleTargets(...args);

export const convertMessageTarget: typeof targetApi.convertTarget = (...args) =>
  targetApi.convertTarget(...args);
