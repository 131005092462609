import QInput from 'quasar/src/components/input/QInput.js';import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';import useFormChild from 'quasar/src/composables/use-form-child.js';;
import { computed, ref, Ref, watch } from "vue";

import {
  dateTimeFromString,
  isDateTimeStringValidFormat,
} from "@/lib/dateTime";
import { ValidationRule } from "@/lib/rules/typedefs";

interface DateTimeInputOptions {
  modelValue: Ref<string | null>;
  onDateTimeChange: (arg: string | null) => void;
  extraRules?: Ref<ValidationRule<string | null>[]>;
}

const DEFAULT_RULES: ValidationRule<string | null>[] = [
  (val: string | null) =>
    val === null ||
    val === "" ||
    isDateTimeStringValidFormat(val) ||
    "This is not a valid date time",
];

export function useDateTimeInput({
  modelValue,
  onDateTimeChange,
  extraRules,
}: DateTimeInputOptions) {
  const time: Ref<string | null> = ref(null);
  const date: Ref<string | null> = ref(null);
  const popupRef: Ref<QPopupProxy | null> = ref(null);
  const inputText: Ref<string | null> = ref(null);

  watch(
    [modelValue],
    () => {
      if (modelValue.value === null) {
        return;
      }

      const parsedDateTime = dateTimeFromString(modelValue.value);
      if (parsedDateTime === null) {
        return;
      }

      if (time.value !== parsedDateTime.time) {
        time.value = parsedDateTime.time;
      }

      if (date.value !== parsedDateTime.date) {
        date.value = parsedDateTime.date;
      }

      if (inputText.value !== `${date.value} ${time.value}`) {
        inputText.value = `${date.value} ${time.value}`;
      }
    },
    { immediate: true }
  );

  const onInputTextChange = () => {
    if (!inputText.value) {
      onDateTimeChange(null);
      return;
    }

    const isValidFormat = isDateTimeStringValidFormat(inputText.value);
    if (!isValidFormat) {
      return;
    }

    onDateTimeChange(inputText.value);
  };

  const rules = computed(() => {
    return [...DEFAULT_RULES, ...(extraRules?.value ?? [])];
  });

  const inputRef: Ref<QInput | null> = ref(null);

  useFormChild({
    validate: () => inputRef.value?.validate?.() ?? false,
    resetValidation: () => inputRef.value?.resetValidation?.(),
    requiresQForm: false,
  });

  const dateValidationRules: ValidationRule<string | null>[] = [
    (val: string | null) => (val !== null && val !== "") || "Date is required",
  ];

  const setDateTime = () => {
    if (time.value !== null && date.value !== null) {
      const dateTime = `${date.value} ${time.value}`;
      inputText.value = dateTime;
      if (isDateTimeStringValidFormat(dateTime)) {
        onDateTimeChange(inputText.value);
      }
    } else if (inputText.value !== null) {
      inputText.value = null;
      onDateTimeChange(inputText.value);
    }
  };

  const onFormSubmit = async () => {
    setDateTime();
    popupRef.value?.hide();
  };

  return {
    rules,
    date,
    time,
    inputText,
    onInputTextChange,
    inputRef,
    popupRef,
    dateValidationRules,
    onFormSubmit,
  };
}

export const TEST_ONLY = { DEFAULT_RULES };
