import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }

import { ActionConfiguration } from "./typedefs";


export default /*@__PURE__*/_defineComponent({
  __name: 'FormAction',
  props: {
    config: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!

  return (props.config.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_q_btn, {
          loading: props.config.loading,
          disable: props.config.disabled,
          color: props.config.color,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('click')))
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 8, ["loading", "disable", "color"]),
        (_ctx.config.tooltip)
          ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.config.tooltip), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})