import date from 'quasar/src/utils/date.js';;

import { Composable } from "@/lib/typing";

interface UseConversionReportReturn {
  getLookerLink: (
    messageId: string,
    successRateProperty: string | null,
    successRateValue: string | boolean | null,
    dateRange: Record<string, string> | string | null,
    messageType: string
  ) => string;
  formatDateRange: (dateRange: Record<string, string> | null) => string;
}
export enum MessageTypeEnum {
  inApp = "InApp",
  pushNotification = "Push Notification",
}
export const UseConversionReport: Composable<
  void,
  UseConversionReportReturn
> = () => {
  function formatSuccessRateValue(
    successRateValue: string | boolean | null
  ): string {
    if (successRateValue === null) {
      return "";
    }
    if (typeof successRateValue === "boolean") {
      successRateValue = successRateValue ? "Yes" : "No";
    }
    return successRateValue;
  }

  function formatSuccessRateProperty(
    successRateProperty: string | null
  ): string {
    if (successRateProperty === null) {
      return "";
    }
    return '"' + successRateProperty + '"';
  }

  function formatDateRange(
    dateRange: Record<string, string> | string | null
  ): string {
    if (dateRange === null) {
      return "";
    }
    if (typeof dateRange === "string") {
      return dateRange;
    }

    return dateRange.from + " to " + dateRange.to;
  }

  function formatDateData(
    dateRange: Record<string, string> | string | null
  ): string {
    if (
      typeof dateRange === undefined ||
      dateRange === null ||
      dateRange === ""
    ) {
      return "";
    }

    if (typeof dateRange === "string") {
      return dateRange;
    }

    // YYYY/MM/DD to YYYY/MM/DD
    const from = dateRange.from.split("-");
    const toDate = date.extractDate(dateRange.to, "YYYY-MM-DD");
    //add one day since  in looker end/to date is exclusive
    const toInclusive = date.addToDate(toDate, { day: 1 });

    return `${from[0]}/${from[1]}/${from[2]} to ${date.formatDate(
      toInclusive,
      "YYYY/MM/DD"
    )}`;
  }

  function getLookerLink(
    messageId: string,
    successRateProperty: string | null,
    successRateValue: string | boolean | null,
    dateRange: Record<string, string> | string | null,
    messageType: string
  ): string {
    const url = new URL("https://opera.cloud.looker.com/dashboards/684");
    const params = new URLSearchParams(url.search);

    params.append("Message ID", messageId);

    params.append(
      "Success Rate Value",
      formatSuccessRateValue(successRateValue)
    );

    params.append("Date", formatDateData(dateRange));
    params.append(
      "Success Rate Property",
      formatSuccessRateProperty(successRateProperty)
    );
    params.append("Message Type", messageType);

    url.search = params.toString();
    return url.toString();
  }

  return {
    getLookerLink,
    formatDateRange,
  };
};
