import { httpClient, throwIfBadResponse } from "@/lib/http";

import { ImageMetadata, ImageResponse } from "./typedefs";

function convertImages(data: unknown): ImageResponse {
  return data as {
    results: Array<ImageMetadata>;
    next: string;
  };
}

export async function fetchImages(
  imageConstraintNames: string[],
  query: string,
  page: number,
  perPage: number
) {
  const queryParams = {
    constraints: imageConstraintNames.join(","),
    q: query,
    per_page: String(perPage),
    page: String(page),
  };

  const response = await httpClient.get(
    "/api/image-list?" + new URLSearchParams(queryParams).toString()
  );

  await throwIfBadResponse(response);

  const result = await response.json();
  return convertImages(result);
}
