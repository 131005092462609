
import { defineComponent } from "vue";
import { onBeforeRouteUpdate } from "vue-router";

import MessageForm from "@/components/pushmessages/MessageForm.vue";
import MessageHeader from "@/components/pushmessages/MessageHeader.vue";
import { mapParamsRouteGuard } from "@/router/mapParamsRouteGuard";

import { usePushMessage } from "./usePushMessage";
import { usePushMessageDependencyInjector } from "./usePushMessageDependencyInjector";

export default defineComponent({
  components: {
    MessageHeader,
    MessageForm,
  },
  setup() {
    onBeforeRouteUpdate(mapParamsRouteGuard);
    return usePushMessage({
      ...usePushMessageDependencyInjector(),
    });
  },
});
