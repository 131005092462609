import { Ref } from "vue";

import { fetchImages } from "@/api/image/list";
import { ImageMetadata } from "@/api/image/typedefs";
import { uploadImage } from "@/api/image/upload";
import { FilterEventPayload, ScrollEventPayload } from "@/api/typedefs";
import { usePaginatedSelect } from "@/composables/usePaginatedSelect2";
import { Composable, ReadonlyRef } from "@/lib/typing";

class ImageSelectError extends Error {}

export interface UseImageSelectOptions {
  fileUploadRef: Ref<HTMLInputElement | null>;
  fileSelectedCallback: (file: ImageMetadata) => void;
  imageConstraintNames: string[];
  onUploadSuccessCallback: (message: string) => void;
  onUploadErrorCallback: (message: string) => void;
}

interface UseImageSelectReturn {
  startFileUploadRefListener: () => void;
  openFilePicker: () => void;
  fetchImageOptions: (payload: FilterEventPayload) => Promise<void>;
  fetchMoreImageOptions: (payload: ScrollEventPayload) => Promise<void>;
  imageOptions: ReadonlyRef<{ id: number; name: string; url: string }[]>;
  tryToUploadImage: (
    file: File
  ) => Promise<{ id: number; name: string; url: string } | undefined>;
}

function resetFilePicker(filePickerElement: HTMLInputElement) {
  filePickerElement.value = "";
}

export const useImageSelect: Composable<
  UseImageSelectOptions,
  UseImageSelectReturn
> = ({
  fileUploadRef,
  fileSelectedCallback,
  imageConstraintNames,
  onUploadSuccessCallback,
  onUploadErrorCallback,
}) => {
  function startFileUploadRefListener() {
    if (!fileUploadRef.value) {
      throw new ImageSelectError(
        "Initialized FileUploadListener without fileUploadRef"
      );
    }
    fileUploadRef.value.onchange = async () => {
      const files = fileUploadRef.value?.files;
      if (!files?.length || files?.length < 1) return;
      const image = await tryToUploadImage(files[0]);
      if (image) {
        fileSelectedCallback(image);
      }
    };
  }

  function openFilePicker() {
    if (fileUploadRef.value) {
      resetFilePicker(fileUploadRef.value);
    }
    fileUploadRef.value?.dispatchEvent(new MouseEvent("click"));
  }

  const {
    fetchOptions: fetchImageOptions,
    fetchMoreOptions: fetchMoreImageOptions,
    options: imageOptions,
  } = usePaginatedSelect({
    paginatedFetchMethod: (query, page, perPage) =>
      fetchImages(imageConstraintNames, query, page, perPage),
  });

  const tryToUploadImage = async (file: File) => {
    try {
      const image = await uploadImage(imageConstraintNames, file);
      onUploadSuccessCallback("Image successfully uploaded");
      return image;
    } catch (error: unknown) {
      if (error instanceof Error) {
        onUploadErrorCallback(error.message);
      } else {
        throw error;
      }
    }
  };

  return {
    startFileUploadRefListener,
    openFilePicker,
    fetchImageOptions,
    fetchMoreImageOptions,
    imageOptions,
    tryToUploadImage,
  };
};
