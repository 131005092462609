import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-gutter-y-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_time_input = _resolveComponent("time-input")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_date_range_input = _resolveComponent("date-range-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_time_input, {
      modelValue: _ctx.time,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.time) = $event)),
      disable: _ctx.disable,
      "data-test": "deliveryoptions.recurring.time",
      label: "Time",
      hint: "Time of recurring push execution",
      "extra-rules": [
        (val) => (val !== null && val !== '') || 'Time is required',
      ]
    }, null, 8, ["modelValue", "disable", "extra-rules"]),
    _createVNode(_component_q_input, {
      ref: "intervalInputRef",
      modelValue: _ctx.interval,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.interval) = $event)),
      modelModifiers: { number: true },
      disable: _ctx.disable,
      "data-test": "deliveryoptions.recurring.interval",
      type: "number",
      label: "Interval",
      hint: "Execution frequency (every X days)",
      filled: "",
      clearable: "",
      rules: [
        (val) => (val !== null && val !== '') || 'Interval is required',
        (val) =>
          (typeof val === 'number' && val > 0) || 'Interval should be positive',
        (val) =>
          (typeof val === 'number' && val <= 365) ||
          'Interval should not be bigger than 365',
      ]
    }, null, 8, ["modelValue", "disable", "rules"]),
    _createVNode(_component_date_range_input, {
      modelValue: _ctx.dateRange,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dateRange) = $event)),
      disable: _ctx.disable,
      "data-test": "deliveryoptions.recurring.daterange",
      label: "Date range",
      hint: "When to execute recurring push",
      "extra-rules": [
        (val) => (val !== null && val !== '') || 'Date range is required',
      ]
    }, null, 8, ["modelValue", "disable", "extra-rules"])
  ]))
}