import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { Ref } from "vue";

import { ProductLine } from "@/lib/productLine";
import { ReadonlyRef } from "@/lib/typing";

import CloneDialog from "./CloneDialog.vue";

interface CloneDialogOptions {
  onDismiss?: () => void;
  onOk: (arg: { product: number; productLine: number }) => void;
  defaultProductLine?: Ref<ProductLine | null> | null;
  productLineOptions?: ReadonlyRef<ProductLine[]> | null;
}

export function useCloneDialog(options: CloneDialogOptions) {
  const $q = useQuasar();
  const openDialog = () => {
    $q.dialog({
      component: CloneDialog,
      componentProps: {
        defaultProductLine: options.defaultProductLine?.value,
        productLineOptions: options.productLineOptions?.value,
      },
    })
      .onOk((payload: unknown) => {
        const castedPayload = payload as {
          product?: unknown;
          productLine?: unknown;
        };
        if (
          typeof castedPayload.product !== "number" ||
          typeof castedPayload.productLine !== "number"
        ) {
          throw new Error("MessageCloneDialog returned wrong payload type.");
        }
        const product = Number(castedPayload.product);
        const productLine = Number(castedPayload.productLine);
        options.onOk({ product, productLine });
      })
      .onDismiss(() => options?.onDismiss?.());
  };

  return {
    openDialog,
  };
}
