import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_time_input = _resolveComponent("date-time-input")!

  return (_openBlock(), _createBlock(_component_date_time_input, {
    "data-test": "deliveryoptions.scheduled.datetime",
    disable: _ctx.disable,
    label: "Delivery date time",
    hint: "When to execute scheduled push",
    "model-value": _ctx.dateTime,
    "extra-rules": [
      (val) => (val !== null && val !== '') || 'Delivery date time is required',
    ],
    "onUpdate:modelValue": _ctx.onDateTimeChange
  }, null, 8, ["disable", "model-value", "extra-rules", "onUpdate:modelValue"]))
}