import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col header"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.productLine !== null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("img", {
          src: _ctx.productLine.iconUrl
        }, null, 8, _hoisted_2),
        _createTextVNode(" " + _toDisplayString(_ctx.productLine.productName) + " / " + _toDisplayString(_ctx.productLine.name) + " / Push Notification ", 1)
      ]))
    : _createCommentVNode("", true)
}